import React from "react";
import {withTranslation} from "react-i18next";
import {IoIosClose} from "react-icons/all";
import Countdown, {zeroPad} from "react-countdown";
import keycloak from "../keycloak";
import {Link} from "react-router-dom";


class HeaderBanner extends React.Component {
    state = {
        hide: false
    };

    componentDidMount() {
        document.body.classList.add("AppMargin")
    }

    hide = () => {
        this.setState({hide: true})
        document.body.classList.remove("AppMargin")
    }

    render() {
        const {t} = this.props;

        // Renderer callback with condition
        const bannerTimer = ({days, hours, minutes, seconds, completed}) => {
            if (completed) {
                // Render a completed state
                return <div className='banner-timer'>
                    <div className='row'>
                    <span className='col-2 banner-timer-days'>
                        <span className='banner-timer-days-timer'>{zeroPad(0)}</span>
                        <span className='banner-timer-days-text'>{t('days')}</span>
                    </span>
                        <span className='col-2 banner-timer-hours'>
                        <span className='banner-timer-hours-timer'>{zeroPad(0)}</span>
                        <span className='banner-timer-hours-text'>{t('hour')}</span>
                    </span>
                        <span className='col-2 banner-timer-minutes'>
                        <span className='banner-timer-minutes-timer'>{zeroPad(0)}</span>
                        <span className='banner-timer-minutes-text'>{t('minute')}</span>
                    </span>
                        <span className='col-2 banner-timer-seconds'>
                        <span className='banner-timer-seconds-timer'>{zeroPad(0)}</span>
                        <span className='banner-timer-seconds-text'>{t('second')}</span>
                    </span>
                        <span className='col-4 d-flex justify-content-center align-items-center'>
                        <Link to={"/bundles"}>
                        <p className='btn reg-btn-banner m-0'>{t('start_now')}</p>
                        </Link>
                    </span>
                    </div>
                </div>;
            } else {
                // Render a countdown
                return <div className='banner-timer'>
                    <div className='row justify-content-start'>
                        <span className='col-2 banner-timer-days'>
                            <span className='banner-timer-days-timer'>{zeroPad(days)}</span>
                            <span className='banner-timer-days-text'>{t('days')}</span>
                        </span>
                        <span className='col-2 banner-timer-hours'>
                            <span className='banner-timer-hours-timer'>{zeroPad(hours)}</span>
                            <span className='banner-timer-hours-text'>{t('hour')}</span>
                        </span>
                        <span className='col-2 banner-timer-minutes'>
                            <span className='banner-timer-minutes-timer'>{zeroPad(minutes)}</span>
                            <span className='banner-timer-minutes-text'>{t('minute')}</span>
                        </span>
                        <span className='col-2 banner-timer-seconds'>
                            <span className='banner-timer-seconds-timer'>{zeroPad(seconds)}</span>
                            <span className='banner-timer-seconds-text'>{t('second')}</span>
                        </span>
                        <span className='col-4 d-flex justify-content-center align-items-center'>
                            <Link to={"/bundles"}>
                            <p className='btn reg-btn-banner m-0'>{t('start_now')}</p>
                            </Link>
                        </span>
                    </div>

                </div>;
            }
        };


        return (
            <div className="container-fluid p-0" style={{display: this.state.hide && 'none'}}>
                <div className="Banner">
                    <div className="row h-100 WebView justify-content-evenly align-items-center">
                        <div className="col-4 d-flex justify-content-center align-items-center">
                            <h3 className="bannerTittle m-0">
                                {t("banner_content_start")}</h3>
                        </div>
                        <div className="col-6">
                            <Countdown
                                date={1663020000000}
                                renderer={bannerTimer}
                            />
                        </div>
                        <div className='col-1'>
                            <IoIosClose onClick={() => this.hide()} size="22" className='close-banner'/>
                        </div>
                        {/*<div className="col-1 d-flex justify-content-end align-items-center ">*/}
                        {/*    <IoIosClose onClick={() => this.hide()} size="22" className='close-banner'/>*/}
                        {/*</div>*/}
                    </div>

                    <div className="row MobileView">
                        <div className="col-12 row mb-2">
                            {/*<button type="button" className="btn"*/}
                            {/*><IoIosClose onClick={() => this.hide()} size="22" className='close-banner'/>*/}
                            {/*</button>*/}
                            {/*</div>*/}
                            {/*<div className="col-11 px-4">*/}
                            <p className="col-11 bannerTittle">
                                {/*<IoIosClose onClick={() => this.hide()} size="22" className='close-banner'/>*/}
                                {t("banner_content_start")}</p>
                            <div className='col-1'>
                                <IoIosClose onClick={() => this.hide()} size="22" className='close-banner'/>
                            </div>
                        </div>
                        <div className="col-12">
                            <Countdown
                                date={1663020000000}
                                renderer={bannerTimer}
                            />
                        </div>
                        <div className='col-1'>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default withTranslation()(HeaderBanner);
