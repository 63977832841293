import React, {Component} from 'react';
import {withTranslation} from 'react-i18next';



class EmptyRedirectionRefaller extends Component {


    componentWillMount() {
        window.location.href=process.env.REACT_APP_REDIRECT
    }

    render() {
        return (
            <>
            </>
        );
    }

}

export default withTranslation()(EmptyRedirectionRefaller);
