import React from "react";
import logo1 from "../imgs/vol_logo.jpg";
import avatar1 from "../imgs/256-512.png";
import searchico from "../imgs/searchico.png";
import cartico from "../imgs/cart-1-1.svg";
import {NavLink, withRouter} from "react-router-dom";
import {Badge} from "antd";
import {getParameterByName} from "../components/helper_functions";
import keycloak from "./../keycloak";
import axios from "axios";
import Lang from "../components/lang";
import {DropdownItem, DropdownMenu, DropdownToggle, UncontrolledDropdown,} from "reactstrap";
import * as Icon from "react-feather";
import {withTranslation} from "react-i18next";
import {IoMdArrowDropdown} from "react-icons/all";
import HeaderBanner from "./HeaderBanner";
import {connect} from "react-redux";
import * as types from "../redux/actions/actionTypes";
import {Link} from "react-router-dom";


const onRemoveClick = (e) => {
    document.getElementById("navbarSupportedContent").classList.remove("show");
};


class NavBar extends React.Component {
    state = {
        searchText: "",
        cartCount: 0
    };

    handleRoute = (route) => () => {
        this.props.history.push({pathname: route});
    };

    handleSearchInput = (event) => {
        this.setState({
            searchText: event.target.value,
        });
    };
    handleSearchSubmit = (e) => {
        if (this.state.searchText) {
            e.preventDefault();
            let href = "";
            href += "/search/" + this.state.searchText + "/1" + "/t";
            this.props.history.push({
                pathname: href,
                state: {
                    searchText: this.state.searchText,
                },
            });
        } else {
            e.preventDefault();
        }
    };

    componentDidMount() {
        if (keycloak && keycloak.authenticated) {
            axios.get(process.env.REACT_APP_API_URL, {
                params: {
                  // url: "payment_portal/ar/cart_count/" + keycloak.tokenParsed.email,
                  url: "api/method/variiance.payment_app.api.v2.payment_portal.cart_count?email=" + keycloak.tokenParsed.email,
                }
            })
                .then((response) => {
                    let count = response.data.data;
                    this.props.dispatch({type: types.ADD_TO_CART, payload: {cartCount: count, addOne:'No'}})
                });
        }
        this.setState({searchText: getParameterByName("q")});
    }

    render() {
        const {t} = this.props;
        const {count} = this.state

        return (
            <>
                <div className="fixed-top">
                    <HeaderBanner/>
                    <nav className="navbar flex-row-reverse navbar-expand-lg navbar-light">
                        <div className="row justify-content-around justify-content-lg-start
                            align-items-center" style={{width: !keycloak.authenticated ? '28rem' : 'auto'}}>
                            {/*    <Link to='/add_to_cart' className='col-4 cart-icon'>*/}
                            {/*        <Badge count={this.props.cartReduxState.cart} showZero>*/}
                            {/*            <img*/}
                            {/*                className="ml-2"*/}
                            {/*                style={{width: "22px", height: "22px"}}*/}
                            {/*                alt="cart icon"*/}
                            {/*                src={cartico}*/}
                            {/*            />*/}
                            {/*        </Badge>*/}
                            {/*    </Link>*/}
                            <a href={process.env.REACT_APP_PAYMENT + "/cartpage"} className='col-4 cart-icon'>
                                <Badge count={this.props.cartReduxState.cart} showZero>
                                    <img
                                        className="ml-2"
                                        style={{width: "22px", height: "22px"}}
                                        alt="cart icon"
                                        src={cartico}
                                    />
                                </Badge>
                            </a>

                            {keycloak && !keycloak.authenticated ? (
                                <div className="col-5 bg-btn1">
                                    <button
                                        className="Label-btn1"
                                        onClick={() =>
                                            keycloak && !keycloak.authenticated
                                                ? keycloak.login()
                                                : keycloak.logout()
                                        }
                                    >
                                        {keycloak && !keycloak.authenticated
                                            ? t("Login")
                                            : t("Logout")}
                                    </button>
                                </div>
                            ) : (
                                <ul
                                    className="col-5 ulmob nav navbar-nav navbar-nav-user"
                                    style={{}}
                                >
                                    <UncontrolledDropdown
                                        tag="li"
                                        className="dropdown-user nav-item"
                                    >
                                        <DropdownToggle
                                            tag="a"
                                            className="nav-link dropdown-user-link user-menu"
                                        >
                                      <span data-tour="user">
                                          <img
                                              className="pic ml-5 user-menu-img pt-0"
                                              alt="profile"
                                              src={
                                                  keycloak.tokenParsed.image
                                                      ? keycloak.tokenParsed.image
                                                      : avatar1
                                              }
                                          />
                                        </span>
                                            {/*<span className="mx-1 user-menu-name">*/}
                                            {/*            {keycloak.tokenParsed.given_name}*/}
                                            {/*        </span>*/}

                                        </DropdownToggle>
                                        <DropdownMenu right>
                                            <DropdownItem
                                                tag="a"
                                                href={process.env.REACT_APP_PROFILE}
                                            >
                                                <Icon.User size={14} className="mr-50"/>
                                                <span className="align-middle m-2">
                          {" "}
                                                    {keycloak.tokenParsed.name}{" "}
                        </span>
                                            </DropdownItem>
                                            {keycloak.tokenParsed.resource_access.website
                                                .roles.includes("instructors") === true ?
                                                <DropdownItem
                                                    tag="a"
                                                    href={process.env.REACT_APP_INSTRUCTOR}
                                                >
                                                    <Icon.Linkedin size={14} className="mr-50"/>
                                                    <span className="align-middle m-2">
                        {" "}
                                                        {t("instructor dashboard")}
                      </span>
                                                </DropdownItem> : null}
                                            <DropdownItem
                                                tag="a"
                                                href={process.env.REACT_APP_PARTICIPANT}
                                            >
                                                <Icon.UserCheck size={14} className="mr-50"/>
                                                <span className="align-middle m-2">
                          {" "}
                                                    {t("participant dashboard")}{" "}
                        </span>
                                            </DropdownItem>
                                            <DropdownItem
                                                tag="a"
                                                href={process.env.REACT_APP_PAYMENT}
                                            >
                                                <Icon.CreditCard size={14} className="mr-50"/>
                                                <span className="align-middle m-2">
                          {" "}
                                                    {t("payment portal")}{" "}
                        </span>
                                            </DropdownItem>
                                            {/*                          <DropdownItem*/}
                                            {/*                              tag="a"*/}
                                            {/*                              href={process.env.REACT_APP_VTS}*/}
                                            {/*                          >*/}
                                            {/*                              <Icon.Book size={14} className="mr-50"/>*/}
                                            {/*                              <span className="align-middle m-2">*/}
                                            {/*    {" "}*/}
                                            {/*                                  {t("Vts")}{" "}*/}
                                            {/*  </span>*/}
                                            {/*                          </DropdownItem>*/}

                                            {/*                          <DropdownItem tag="a" href={process.env.REACT_APP_VLC}>*/}
                                            {/*                              <Icon.Wifi size={14} className="mr-50"/>*/}
                                            {/*                              <span className="align-middle m-2">*/}
                                            {/*  {" "}*/}
                                            {/*                                  {t("VLC")}{" "}*/}
                                            {/*</span>*/}
                                            {/*                          </DropdownItem>*/}

                                            <DropdownItem divider/>

                                            <DropdownItem
                                                tag="a"
                                                onClick={() => {
                                                    localStorage.removeItem('student_id')
                                                    keycloak.logout()
                                                }}
                                                className="btn-link align-middle"
                                            >
                                                <Icon.Power size={14} className="mr-50"/>
                                                <span
                                                    className=" align-middle"
                                                    onClick={() => {
                                                        localStorage.removeItem('student_id')
                                                        keycloak.logout()
                                                    }}
                                                >
                          {" "}
                                                    {t("Logout")}{" "}
                        </span>
                                            </DropdownItem>
                                        </DropdownMenu>
                                    </UncontrolledDropdown>
                                </ul>
                            )}
                        </div>
                        <div className="container-fluid navcont p-0">
                            <div>
                                <button
                                    className="navbar-toggler p-0"
                                    type="button"
                                    data-bs-toggle="collapse"
                                    data-bs-target="#navbarSupportedContent"
                                    aria-controls="navbarSupportedContent"
                                    aria-expanded="false"
                                    aria-label="Toggle navigation"
                                >
                                    <span className="navbar-toggler-icon"></span>
                                </button>
                                <NavLink className="navbar-brand  my-auto" to="/">
                                    {" "}
                                    <img src={logo1} alt="logo variiance" className='logo-nav' width="30"
                                         onClick={onRemoveClick}
                                    />
                                </NavLink>
                            </div>

                            <div
                                className="collapse navbar-collapse"
                            >

                                <ul className="navbar-nav ">
                                    <li
                                        className="nav-item"
                                        data-toggle="collapse"
                                        data-target=".navbar-collapse.show"
                                    >
                                        <NavLink
                                            to="/"
                                            exact={true}
                                            activeClassName="act"
                                            className="btn btn1"
                                        >
                                            {" "}
                                            {t("Home")}
                                        </NavLink>
                                    </li>
                                    <li
                                        className="nav-item"
                                        data-toggle="collapse"
                                        data-target=".navbar-collapse.show"
                                    >
                                        <NavLink
                                            to="/AllCourses/1"
                                            activeClassName="act"
                                            className="btn btn1"
                                        >
                                            {" "}
                                            {t("Categories")}
                                        </NavLink>
                                    </li>
                                    <li
                                        className="nav-item"
                                        data-toggle="collapse"
                                        data-target=".navbar-collapse.show"
                                    >
                                        <NavLink
                                            to="/bundles/"
                                            activeClassName="act"
                                            className="btn btn1"
                                        >
                                            {" "}
                                            {t("Bundles")}
                                        </NavLink>
                                    </li>
                                </ul>

                                <form
                                    className="searchhome "
                                    onSubmit={this.handleSearchSubmit}
                                >
                                    <div className="input-group" style={{width: "100%"}}>
                                        <input
                                            type="text"
                                            className="form-control outlineborder"
                                            placeholder={t("Searching For")}
                                            onChange={this.handleSearchInput}
                                            value={this.state.searchText || ""}
                                            aria-label="Searching For"
                                            aria-describedby="button-addon2"
                                        />
                                        <button
                                            style={{color: "#908e8e", padding: "3px"}}
                                            className="btn outlineborder"
                                            type="submit"
                                            id="button-addon2"
                                        >
                                            <img
                                                className=" searchpic"
                                                alt="search icon"
                                                src={searchico}
                                                width={34}
                                                height={34}
                                            />
                                        </button>
                                    </div>
                                </form>


                                <div className=" ulmob mx-2">
                                    <Lang/>
                                </div>
                            </div>
                        </div>
                    </nav>
                    <div
                        className="collapse navbar-collapse"
                        id="navbarSupportedContent"
                    >

                        <ul className="navbar-nav ">
                            <li
                                className="nav-item"
                                data-toggle="collapse"
                                data-target=".navbar-collapse.show"
                            >
                                <NavLink
                                    to="/"
                                    exact={true}
                                    activeClassName="act"
                                    className="btn btn1"
                                    onClick={onRemoveClick}
                                >
                                    {" "}
                                    {t("Home")}
                                </NavLink>
                            </li>
                            <li
                                className="nav-item"
                                data-toggle="collapse"
                                data-target=".navbar-collapse.show"
                            >
                                <NavLink
                                    to="/AllCourses/1"
                                    activeClassName="act"
                                    className="btn btn1"
                                    onClick={onRemoveClick}
                                >
                                    {" "}
                                    {t("Categories")}
                                </NavLink>
                            </li>
                            <li
                                className="nav-item"
                                data-toggle="collapse"
                                data-target=".navbar-collapse.show"
                            >
                                <NavLink
                                    to="/bundles/"
                                    activeClassName="act"
                                    className="btn btn1"
                                    onClick={onRemoveClick}
                                >
                                    {" "}
                                    {t("Bundles")}
                                </NavLink>
                            </li>
                        </ul>

                        <form
                            className="searchhome "
                            onSubmit={this.handleSearchSubmit}
                        >
                            <div className="input-group" style={{width: "100%"}}>
                                <input
                                    type="text"
                                    className="form-control outlineborder"
                                    placeholder={t("Searching For")}
                                    onChange={this.handleSearchInput}
                                    value={this.state.searchText || ""}
                                    aria-label="Searching For"
                                    aria-describedby="button-addon2"
                                />
                                <button
                                    style={{color: "#908e8e", padding: "3px"}}
                                    className="btn outlineborder"
                                    type="submit"
                                    id="button-addon2"
                                >
                                    <img
                                        className=" searchpic"
                                        alt="search icon"
                                        src={searchico}
                                        width={34}
                                        height={34}
                                    />
                                </button>
                            </div>
                        </form>


                        <div className=" ulmob mx-2">
                            <Lang/>
                        </div>
                    </div>
                </div>
            </>
        );
    }
}


const mapStateToProps = (reduxState) => ({
    cartReduxState: reduxState
})


export default connect(mapStateToProps)(withRouter(withTranslation()(NavBar)));
