import React, {Component} from 'react';
import timeico from "../imgs/star.png";
// import './all.css';
import {Spin, Tabs} from 'antd';
import Singlecourseavilabilty from "../components/singlecourseavilabilty";
import Singlecourseoverview from "../components/singlecourseoverview";
import axios from 'axios'
import {Link, withRouter} from 'react-router-dom';
import {withTranslation} from 'react-i18next';
import "moment/locale/ar";
import "moment/locale/en-au";
import MetaTags from "react-meta-tags";
import {Helmet} from "react-helmet";
import Moment from "react-moment";
import keycloak from "../keycloak";
import {toast} from "react-toastify";
import creditCardIcon from "../imgs/credit-card.svg";
import addToCartIcon from "../imgs/add_to_cart.svg";
import * as types from "../redux/actions/actionTypes";
import {connect} from "react-redux";
import {GrCart} from "react-icons/all";

const momentDurationFormatSetup = require("moment-duration-format");
const moment = require("moment");
const {TabPane} = Tabs;

class Course extends Component {
    state = {
        data: [],
        coursecount: [],
        dataprop: [],
        alldata: [],
        isLoading: true,
        url: "",
        disableFree: false,
        rounds: [],
        value: "",
        selectedProduct: [],
        method: "",
        isChecked: true,
        subTotal: 0,
        alertt: "",
        visible: false,
        checkIfOneTime: false,
        buyRound:false

    }


    async componentDidMount() {
        let whichBtnPressed = localStorage.getItem('whichBtnPressed')
        let courseInCourse = localStorage.getItem('courseInCourse')

        this.setState({url: this.props.match.url})

        let set = this.props.match.params.name
        let lang = ""
        if (localStorage.getItem('i18nextLng') === "en" || localStorage.getItem('i18nextLng') === "en-US") {
            this.setState({lang: "en"})
            lang = "en"
            moment.locale('en-au');

        } else {
            this.setState({lang: "ar"})
            lang = "ar"
            moment.locale('ar');

        }
        let userId = ""
        if (keycloak.authenticated) {
            const params = {
                // url: "profile/ar/get_user",
                url: "/api/method/variiance.users.api.v2.profile.get_user",
                data: {
                    sso_sub: keycloak.tokenParsed.sub,
                    student: keycloak.tokenParsed.email,
                    name: keycloak.tokenParsed.given_name,
                    last_name: keycloak.tokenParsed.family_name,
                    student_mobile_number: keycloak.tokenParsed.phone_number ? keycloak.tokenParsed.phone_number : '',
                    service_source: "VOL"
                }
            }
            await axios.post(process.env.REACT_APP_API_URL, params).then((response) => {
                userId = response.data.data.name
            });

        } else {
            userId = " "
        }
        await axios.get(process.env.REACT_APP_API_URL, {
            params: {
                // url: 'courses/' + lang + '/show/' + set +"/"+ userId
                url: `api/method/variiance.vol.api.v2.courses.show?lang=${lang}&course_id=${set}&user=${userId}`
            }
        })
            .then(response => {
                let alldata = response.data.data
                let data = response.data.data.course
                let coursecount = response.data.data.student_count
                this.setState({alldata})
                this.setState({data})
                this.setState({coursecount})
                this.setState({dataprop: <Singlecourseoverview prop1={this.state.alldata}/>})
                this.setState({dataprop1: <Singlecourseavilabilty prop2={this.state.alldata}/>})
                // if (alldata.rounds.length !== 0 && alldata.rounds[0].price_after !== 0) {
                //     this.addToCart(alldata.rounds[0], this.state.isChecked, 0)
                //
                // }
                if (response.data.data.rounds[0]?.is_enrolled == 1) {
                    this.setState({enrolledSuccess: true})
                } else {
                    this.setState({enrolledSuccess: false})
                }
                this.setState({
                    isLoading: false,
                })
            }).then(() => {
                if (whichBtnPressed === 'buyNow' && !this.state.enrolledSuccess) {
                    this.autoBuyNow(courseInCourse)
                } else if (whichBtnPressed === 'addToCart' && !this.state.enrolledSuccess) {
                    this.autoAddToCart(courseInCourse)
                }
            });
    }



    autoAddToCart = async (course) => {
        if (keycloak && keycloak.authenticated) {
            await this.addToCart(course)
        } else {
            localStorage.removeItem('whichBtnPressed')
            localStorage.removeItem('courseInCourse')
        }
    }

    autoBuyNow = async (course) => {
        if (keycloak && keycloak.authenticated) {
            await this.autoAddToCart(course)
            window.location.href = process.env.REACT_APP_PAYMENT + "/cartpage"
        }
        localStorage.removeItem('whichBtnPressed')
        localStorage.removeItem('courseInCourse')
    }

    callAddToCartFunc = async (round, t) => {
        this.setState({buyRound:true})
        const roundObj = typeof round === "string" ? JSON.parse(round) : round
        let cart_items = [
            {
                "buyable_type": "Course Round",
                "buyable_id": roundObj.name,
                "price": roundObj.price_after,
                "discount": 0,
                "expiry_date": ""
            }
        ];

        const newparams = {
            // url: "payment_portal/ar/add_to_cart",
            url: "api/method/variiance.payment_app.api.v2.payment_portal.add_to_cart",
            data: {
                user_email: keycloak.tokenParsed.email,
                cart_items
            },
        };

        await axios.post(process.env.REACT_APP_API_URL, newparams).then((response) => {
            let status = response.data.data.status;
            if (status !== false) {
                toast.success(t("added"), {
                    position: toast.POSITION.TOP_RIGHT
                })
                this.setState({buyRound:false})
                this.props.dispatch({type: types.ADD_TO_CART, payload: {cartCount: 1}})
            } else {
                this.setState({buyRound:false})
                toast.error(t("itemExists"), {
                    position: toast.POSITION.TOP_RIGHT
                })

            }
        });
    }

    addToCart = async (round) => {
        const {t} = this.props
        if (keycloak && keycloak.authenticated) {
            this.setState({buyRound:true})
            await this.callAddToCartFunc(round, t)
            localStorage.removeItem('whichBtnPressed')
            localStorage.removeItem('courseInCourse')
        } else {
            localStorage.setItem('whichBtnPressed', 'addToCart')
            localStorage.setItem('courseInCourse', JSON.stringify(round))

            toast.error(t("pleaselogin"), {
                position: toast.POSITION.TOP_RIGHT
            })

            setTimeout(() => {
                keycloak.login()
            }, 2500);
        }
    };

    buyNow = async (round) => {
        const {t} = this.props
        if (keycloak && keycloak.authenticated) {
            this.setState({buyRound:true})
            await this.addToCart(round)
            localStorage.removeItem('whichBtnPressed')
            localStorage.removeItem('courseInCourse')
            window.location.href = process.env.REACT_APP_PAYMENT + "/cartpage"
        } else {
            localStorage.setItem('whichBtnPressed', 'buyNow')
            localStorage.setItem('courseInCourse', JSON.stringify(round))

            toast.error(t("pleaselogin"), {
                position: toast.POSITION.TOP_RIGHT
            })
            setTimeout(() => {
                keycloak.login()
            }, 2500);
        }
    }


    // addToCart = (res, checked, index, e) => {
    //     console.log(res)
    //     if (index === 0) {
    //         this.setState(
    //             {
    //                 isChecked: !this.state.isChecked,
    //             },
    //             () => {
    //                 let round = {
    //                     buyable_type: "Course Round",
    //                     buyable_id: res.name,
    //                     delivery: res.delivery_method,
    //                     price: res.price_after,
    //                     instructor: res.instructor,
    //                     first_name: res.first_name,
    //                     last_name: res.last_name,
    //                     discount: 0,
    //                 };
    //
    //                 if (
    //                     !this.state.selectedProduct.find((obj) => obj.buyable_id === res.name)
    //                 ) {
    //                     this.setState(
    //                         {
    //                             selectedProduct: [...this.state.selectedProduct, round],
    //                             checkIfOneTime: true
    //                         },
    //                         () => {
    //                             this.sumTotal();
    //                         }
    //                     );
    //                 } else {
    //                     const items = this.state.selectedProduct.filter(
    //                         (item) => item.buyable_id !== res.name
    //                     );
    //                     this.setState({selectedProduct: items, checkIfOneTime: false}, () => {
    //                         this.sumTotal();
    //                     });
    //                 }
    //             }
    //         );
    //     } else {
    //         this.setState(
    //             {
    //                 isChecked: !this.state.isChecked,
    //             },
    //             () => {
    //                 let round = {
    //                     buyable_type: "Course Round",
    //                     buyable_id: res.name,
    //                     delivery: res.delivery_method,
    //                     price: res.price_after,
    //                     instructor: res.instructor,
    //                     first_name: res.first_name,
    //                     last_name: res.last_name,
    //                     discount: 0,
    //                 };
    //
    //                 if (
    //                     !this.state.selectedProduct.find((obj) => obj.buyable_id === res.name)
    //                 ) {
    //                     this.setState(
    //                         {
    //                             selectedProduct: [...this.state.selectedProduct, round],
    //                         },
    //                         () => {
    //                             this.sumTotal();
    //                         }
    //                     );
    //                 } else {
    //                     const items = this.state.selectedProduct.filter(
    //                         (item) => item.buyable_id !== res.name
    //                     );
    //                     this.setState({selectedProduct: items}, () => {
    //                         this.sumTotal();
    //                     });
    //                 }
    //             }
    //         );
    //     }
    // };
    //
    // handleSubmit = () => {
    //     const {t} = this.props
    //     if (keycloak && keycloak.authenticated) {
    //         const newparams = {
    //             url: "payment_portal/ar/add_to_cart",
    //             data: {
    //                 user_email: keycloak.tokenParsed.email,
    //                 first_name: keycloak.tokenParsed.given_name,
    //                 last_name: keycloak.tokenParsed.family_name,
    //                 student_mobile_number: keycloak.tokenParsed.phone_number,
    //                 cart_items: this.state.selectedProduct,
    //                 sub_total: this.state.subTotal,
    //                 vat: 0,
    //                 total: this.state.subTotal,
    //             },
    //         };
    //
    //         axios.post(process.env.REACT_APP_API_URL, newparams)
    //             .then((response) => {
    //                 let res = response.status;
    //                 if (res === 200) {
    //                     this.setState({selectedProduct: [], subTotal: 0});
    //                     this.notifyDone(t);
    //
    //                     this.props.dispatch({type: types.ADD_TO_CART, payload: {cartCount: 1}})
    //
    //                     // setInterval(() => {
    //                     //     window.location.reload(false);
    //                     // }, 600);
    //                 } else {
    //                 }
    //             });
    //     } else {
    //         this.notifyLogin(t)
    //         setTimeout(() => {
    //             keycloak.login()
    //         }, 2500);
    //
    //         // this.show();
    //         // this.setState({ alertt: "error" });
    //     }
    // };
    //
    // sumTotal() {
    //     let sum = 0;
    //     sum = this.state.selectedProduct.reduce((prev, res) => {
    //         return prev + parseFloat(res.price);
    //     }, 0);
    //     this.setState({subTotal: sum}, () => {
    //     });
    // }
    //
    // scrollTab = () => ({direction: "right"})
    //
    // buyNow = async () => {
    //     const {t} = this.props
    //     if (keycloak && keycloak.authenticated) {
    //         await this.handleSubmit()
    //         window.location.href = process.env.REACT_APP_PAYMENT + "/cartpage"
    //         localStorage.removeItem('whichBtnPressed')
    //     } else {
    //         localStorage.setItem('whichBtnPressed', 'buyNow')
    //         toast.error(t("pleaselogin"), {
    //             position: toast.POSITION.TOP_RIGHT
    //         })
    //         setTimeout(() => {
    //             keycloak.login()
    //         }, 2500);
    //     }
    // }

    addFreeCourses = (round_id) => {
        const {t} = this.props
        if (keycloak && keycloak.authenticated) {
            this.setState({disableFree: true})
            let lang = ""
            if (localStorage.getItem('i18nextLng') === "en" || localStorage.getItem('i18nextLng') === "en-US") {
                this.setState({lang: "en"})
                lang = "en"
                moment.locale('en-au');

            } else {
                this.setState({lang: "ar"})
                lang = "ar"
                moment.locale('ar');

            }
            const Params = {
                url: `courses/${lang}/enroll`,
                data: {
                    user_email: keycloak.tokenParsed.email,
                    round_id: round_id
                },
            };
            axios.post(process.env.REACT_APP_API_URL, Params)
                .then((response) => {
                    let res = response.data.status;
                    if (res === true) {
                        this.notifyFreeCoursesSuccess(t)
                        this.setState({enrolledSuccess: true})
                    }
                }).catch(error => {
                this.notifyFreeCourses(t)
                this.setState({disableFree: false})

            })
        } else {
            this.notifyLogin(t)
            setTimeout(() => {
                keycloak.login()
            }, 2500);
        }
    }


    notifyDone = (t) =>

        toast.warning(t("added"), {

            position: toast.POSITION.TOP_RIGHT
        })

    notifyLogin = (t) =>
        toast.error(t("pleaselogin"), {
            position: toast.POSITION.TOP_RIGHT
        })
    notifyFreeCourses = (t) =>
        toast.error(t("you are already enrolled before"), {
            position: toast.POSITION.TOP_RIGHT
        })
    notifyFreeCoursesSuccess = (t) =>
        toast.warning(t("you are enrolled successfully"), {
            position: toast.POSITION.TOP_RIGHT
        })

    render() {
        const {t} = this.props;

        let {data, alldata, dataprop, dataprop1} = this.state


        let rendered = this.state.isLoading ?
            (
                <div className="container cont">
                    <div className="row">
                        <div className="col-lg-12 col-md-12">

                            <div className="example">
                                <Spin size="large" style={{margin: "auto"}}/>
                            </div>
                        </div>
                    </div>
                </div>

            ) : (
                <>
                    <div className="container cont mb-5">
                        <div className="row">
                            <div className="col-lg-12 col-md-12">
                                {/*<div className="mt-5">*/}
                                    <img className="firstimg mt-3" alt={data.image_alt}
                                         src={data.image}/>
                                {/*</div>*/}
                            </div>
                        </div>

                        <div className="row course2 mt-3">
                            <div id="ff" className="col-lg-4 col-md-6 ">
                                <img src={timeico} alt="time icon" width={21} height={21}/>
                                {localStorage.getItem('i18nextLng') === "en" || localStorage.getItem('i18nextLng') === "en-US" ?
                                    <span className="span1">{moment
                                        .duration(data.course_houre, "minutes")
                                        .format("h [hr]  m [min]", {trim: "both"})}</span> :
                                    <span className="span1">{moment
                                        .duration(data.course_houre, "minutes")
                                        .format("h [ساعة]  m [دقيقة]", {trim: "both"})}</span>}
                                {/* <span className="span2">{coursecount}</span> */}
                                <Link className="span2 removelink5"
                                      to={"/search/" + encodeURIComponent(data.category) + "/1" + "/c"}>{data.category_name}</Link>
                            </div>
                        </div>

                        <div id="fff" className="row  my-4 alignText">
                            <div className="col-lg-6">
                                <h1>{data.title}</h1>
                            </div>
                            <div className="col-lg-6">
                                <div
                                    className="d-flex flex-lg-row justify-content-lg-end flex-md-row justify-content-md-start flex-column justify-content-center align-items-center">
                                    {alldata.rounds[0] ? alldata.rounds[0].price_after < alldata.rounds[0].price_befor ?
                                        <h2 className="marginMobile"
                                            style={{color: "#ee851d"}}>{alldata.rounds[0].price_after !== 0 ? alldata.rounds[0].price_after + " " + t('EGP') : t('Free')}{" "}
                                            <span style={{
                                                textDecoration: "line-through",
                                                fontSize: "14px",
                                                color: "#908e8e",
                                                margin: "0px 10px"
                                            }}>{alldata.rounds[0].price_befor + " " + t('EGP')}</span>
                                        </h2> : <h2 className="marginMobile"
                                                    style={{color: "#ee851d"}}>{alldata.rounds[0].price_after !== 0 ? alldata.rounds[0].price_after + " " + t('EGP') : t('Free')}</h2> : null}

                                    {alldata.rounds[0] ? alldata.rounds[0].price_after === 0 ?
                                        <>
                                            {!this.state.enrolledSuccess ?
                                                <button
                                                    href="#"
                                                    className="btn buttonbg w-100"
                                                    disabled={this.state.disableFree ? true : false}
                                                    onClick={() => this.addFreeCourses(alldata.rounds[0].name)}
                                                >
                                                    {t("Enroll now")}
                                                </button> :
                                                <a href={process.env.REACT_APP_PARTICIPANT + "/singlecourse/" + alldata.rounds[0].name}
                                                   target="_blank">
                                                    <button
                                                        href="#"
                                                        className="btn buttonbg"
                                                    >
                                                        {t("view Course")}
                                                    </button>
                                                </a>}
                                        </>
                                        : !this.state.enrolledSuccess ?
                                            // <button
                                            //     href="#"
                                            //     className="btn buttonbg "
                                            //     // disabled={this.state.selectedProduct.length === 0 ? true : false}
                                            //     onClick={() => this.handleSubmit()}
                                            // >
                                            //     {t("Add to Cart")}
                                            // </button>
                                            <div className='my-modal-body-first-section-pricing'>
                                                <div className='my-modal-body-first-section-pricing-button-container'>
                                                    <span className='button-container-buy'>
                                                        {/*<img*/}
                                                        {/*    className="button-container-buy-icon"*/}
                                                        {/*    alt="creditCardIcon"*/}
                                                        {/*    src={creditCardIcon}*/}
                                                        {/*/>*/}
                                                        <button
                                                            className="btn button-container-buy-btn w-100"
                                                            disabled={this.state.buyRound}
                                                            onClick={() => this.buyNow(alldata.rounds[0])}
                                                        >
                                                        {t("buy_now")}
                                                        </button>
                                                    </span>
                                                    <span className='button-container-add-to-cart'>
                                                        <button className='w-100 h-100 p-0' style={{background:"unset",border:"none"}}
                                                                disabled={this.state.buyRound}
                                                                onClick={() => this.addToCart(alldata.rounds[0])}
                                                        >
                                                             <GrCart size='30' className="button-container-add-to-cart-icon"
                                                                    />
                                                        </button>
                                                        {/*<img*/}
                                                        {/*    className="button-container-add-to-cart-icon"*/}
                                                        {/*    alt="addToCartIcon"*/}
                                                        {/*    src={addToCartIcon}*/}
                                                        {/*/>*/}
                                                        {/*<button*/}
                                                        {/*    className="btn button-container-add-to-cart-btn"*/}
                                                        {/*    onClick={() => this.handleSubmit()}*/}
                                                        {/*>*/}
                                                        {/*{t("Add to Cart")}*/}
                                                        {/*</button>*/}
                                                    </span>
                                                </div>
                                                {/*<p className='my-modal-body-first-section-pricing-price'>*/}
                                                {/*    {t('price_is')}{bundle.price_after} {t('EGP')}*/}
                                                {/*    <span style={{*/}
                                                {/*        'color': 'red',*/}
                                                {/*        'textDecoration': 'line-through'*/}
                                                {/*    }}>{bundle.price_befor} {t('EGP')}</span>*/}
                                                {/*</p>*/}
                                            </div>
                                            :
                                            <a href={process.env.REACT_APP_PARTICIPANT + "/singlecourse/" + alldata.rounds[0].name}
                                               target="_blank">
                                                <button
                                                    href="#"
                                                    className="btn buttonbg"
                                                >
                                                    {t("view Course")}
                                                </button>
                                            </a> : null}
                                </div>

                                {/*{data.displayed_price_after < data.displayed_price_before ?*/}
                                {/*    <h2>{data.displayed_price_after !== 0? data.displayed_price_after +" "+ t('EGP') : t('Free')}{" "}*/}
                                {/*        <span style={{*/}
                                {/*            textDecoration: "line-through",*/}
                                {/*            fontSize: "14px",*/}
                                {/*            color: "#908e8e",*/}
                                {/*            margin:"0px 10px"*/}
                                {/*        }}>{data.displayed_price_before +" "+ t('EGP')}</span>*/}
                                {/*    </h2>: <h2>{data.displayed_price_after !== 0? data.displayed_price_after +" "+ t('EGP') : t('Free')}</h2>}*/}


                            </div>

                        </div>
                        <Tabs tabPosition="top" defaultActiveKey="1"
                              direction={localStorage.getItem('i18nextLng') === "en" || localStorage.getItem('i18nextLng') === "en-US" ? 'ltr' : localStorage.getItem('i18nextLng') === "ar" ? 'rtl' : 'ltr'}>
                            <TabPane tab={t('Overview')} key="1">
                                {dataprop}
                            </TabPane>
                            <TabPane tab={t("Who Should Attend ?")} key="2">
                                <div className="dataErp"
                                     dangerouslySetInnerHTML={{__html: data.who_should_attend}}></div>
                            </TabPane>
                            <TabPane tab={t("Course Content")} key="3">
                                <div className="dataErp" ref="dataErp"
                                     dangerouslySetInnerHTML={{__html: data.learning_topics}}></div>
                            </TabPane>
                            <TabPane tab={t("Course Certification")} key="4">
                                <div dangerouslySetInnerHTML={{__html: data.course_certification}}></div>
                            </TabPane>
                            <TabPane tab={t("How Will I be assessed")} key="5">
                                <div className="dataErp"
                                     dangerouslySetInnerHTML={{__html: data.how_will_i_be_assessed}}></div>
                            </TabPane>

                            {/*<TabPane tab={t("Course Availability")} key="6">*/}
                            {/*   */}
                            {/*</TabPane>*/}

                        </Tabs>

                    </div>
                </>
            );
        return (<div>{rendered}
            {/*{dataprop1}*/}
            <Helmet>
                <link href={"https://vol.variiance.com" + this.state.url} rel="canonical"/>
            </Helmet>
            <MetaTags>
                <title>{data.meta_title}</title>
                <meta name="description" content={data.meta_description}/>
                <meta content="IE=edge" httpEquiv="X-UA-Compatible"/>
                <meta property="og:type" content="website"/>
                <meta property="og:url" content={"https://vol.variiance.com" + this.state.url}/>
                <meta property="og:description" content={data.meta_description}/>
                <meta property="og:title" content={data.meta_title}/>
                <meta property="og:image" content={data.image}/>
                <meta property="og:image:alt" content={data.image_alt}/>
                <meta property="og:site_name" content="Vol"/>
                <meta content={data.meta_title} name="twitter:title"/>
                <meta content={data.meta_description} name="twitter:description"/>
                <meta content={data.image} name="twitter:image"/>
                <meta content="summary" name="twitter:card"/>
                <meta name="twitter:url" content={"https://vol.variiance.com" + this.state.url}/>
                <meta content="@Variianceglobal" name="twitter:site"/>
            </MetaTags>
        </div>)
    }
}

export default connect()(withRouter(withTranslation()(Course)));
