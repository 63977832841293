import React, {Component} from 'react';
import {Spin, Tabs} from 'antd';
import axios from 'axios'
import {Link, withRouter} from 'react-router-dom';
import {withTranslation} from 'react-i18next';
import "moment/locale/ar";
import "moment/locale/en-au";
import MetaTags from "react-meta-tags";
import {Helmet} from "react-helmet";
import keycloak from "../keycloak";
import {toast} from "react-toastify";

import star from "../imgs/star.png";
import BriefCoursePopup from "./briefCoursePopup";
import * as types from "../redux/actions/actionTypes";
import {connect} from "react-redux";
import creditCardIcon from "../imgs/credit-card.svg";
import addToCartIcon from "../imgs/add_to_cart.svg";
import {BiTime, FaShoppingCart, GrCart, GrPlayFill} from "react-icons/all";

const nl2br = require('react-nl2br');

const moment = require("moment");

class Bundle extends Component {
    state = {
        bundle: {},
        isChecked: true,
        isLoading: true,
        alertt: "",
        data: [],
        coursecount: [],
        dataprop: [],
        url: "",
        disableFree: false,
        rounds: [],
        value: "",
        selectedProduct: [],
        method: "",
        subTotal: 0,
        visible: false,
        checkIfOneTime: false,
        buyRound:false
    }


    async componentDidMount() {
        let whichBtnPressed = localStorage.getItem('whichBtnPressed')
        let bundleInBundles = localStorage.getItem('bundleInBundles')

        this.setState({url: this.props.match.url})

        let bundle_id = this.props.match.params.name

        let lang = ""
        if (localStorage.getItem('i18nextLng') === "en" || localStorage.getItem('i18nextLng') === "en-US") {
            lang = "en"
            this.setState({lang: "en"});
            moment.locale('en-au');
        } else {
            lang = "ar"
            this.setState({lang: "ar"});
            moment.locale('ar');
        }

        await axios.get(process.env.REACT_APP_API_URL, {
            params: {
                url: `api/method/variiance.vol.api.v2.bundles.get_bundle?bundle_id=${bundle_id}&lang=${lang}`
            }
        }).then(res => {
            let bundle = res.data.message

            this.setState({
                isLoading: false,
                bundle,
            })
        }).then(() => {
            if (whichBtnPressed === 'buyNow') {
                this.autoBuyNow(bundleInBundles)
            } else if (whichBtnPressed === 'addToCart') {
                this.autoAddToCart(bundleInBundles)
            }
        })
    }

    autoAddToCart = async (bundle) => {
        if (keycloak && keycloak.authenticated) {
            await this.addToCart(bundle)
        } else {
            localStorage.removeItem('whichBtnPressed')
            localStorage.removeItem('bundleInBundles')
        }
    }

    autoBuyNow = async (bundle) => {
        if (keycloak && keycloak.authenticated) {
            await this.autoAddToCart(bundle)
            window.location.href = process.env.REACT_APP_PAYMENT + "/cartpage"
        }
        localStorage.removeItem('whichBtnPressed')
        localStorage.removeItem('bundleInBundles')
    }

    callAddToCartFunc = async (bundle, t) => {
        const bundleObj = typeof bundle === "string" ? JSON.parse(bundle) : bundle
        this.setState({buyRound:true})
        let buyingBundle = [{
            buyable_type: "Bundles",
            buyable_id: bundleObj.name,
            price: bundleObj.price_after,
            discount: 0,
        }];

        const newparams = {
            // url: "payment_portal/ar/add_to_cart",
            url: "api/method/variiance.payment_app.api.v2.payment_portal.add_to_cart",
            data: {
                user_email: keycloak.tokenParsed.email,
                cart_items: buyingBundle,
                expiry_date: bundleObj.bundle_expire_on
            },
        };

        await axios.post(process.env.REACT_APP_API_URL, newparams)
            .then((response) => {
                let status = response.data.data.status;
                if (status !== false) {
                    this.setState({selectedProduct: [], subTotal: 0});
                    toast.warning(t("added"), {
                        position: toast.POSITION.TOP_RIGHT
                    })
                    this.setState({buyRound:false})
                    this.props.dispatch({type: types.ADD_TO_CART, payload: {cartCount: 1}})
                    // setInterval(() => {
                    //     window.location.reload();
                    // }, 600);
                } else {
                    this.setState({buyRound:false})
                    toast.error(t("itemExists"), {
                        position: toast.POSITION.TOP_RIGHT
                    })

                }
            })
    }

    addToCart = async (bundle) => {
        const {t} = this.props
        if (keycloak && keycloak.authenticated) {
            this.setState({buyRound:true})
            await this.callAddToCartFunc(bundle, t)
            localStorage.removeItem('whichBtnPressed')
            localStorage.removeItem('bundleInBundles')
        } else {
            localStorage.setItem('whichBtnPressed', 'addToCart')
            localStorage.setItem('bundleInBundles', JSON.stringify(bundle))

            toast.error(t("pleaselogin"), {
                position: toast.POSITION.TOP_RIGHT
            })

            setTimeout(() => {
                keycloak.login()
            }, 2500);
        }
    };

    buyNow = async (bundle) => {
        const {t} = this.props
        if (keycloak && keycloak.authenticated) {
            this.setState({buyRound:true})
            await this.addToCart(bundle)
            localStorage.removeItem('whichBtnPressed')
            localStorage.removeItem('bundleInBundles')
            window.location.href = process.env.REACT_APP_PAYMENT + "/cartpage"
        } else {
            localStorage.setItem('whichBtnPressed', 'buyNow')
            localStorage.setItem('bundleInBundles', JSON.stringify(bundle))

            toast.error(t("pleaselogin"), {
                position: toast.POSITION.TOP_RIGHT
            })
            setTimeout(() => {
                keycloak.login()
            }, 2500);
        }
    }

    appearBriefCoursePopup = (course) => {
        this.setState({appearBriefCourse: true, course_id: course.course_id})
    }

    disAppearPopup = () => {
        this.setState({appearBriefCourse: false})
    }

    render() {
        const {t} = this.props;

        let {data, bundle, appearBriefCourse, course_id, lang} = this.state

        let rendered = this.state.isLoading ?
            (
                <div className="container cont">
                    <div className="row">
                        <div className="col-lg-12 col-md-12">

                            <div className="example">
                                <Spin size="large" style={{margin: "auto"}}/>
                            </div>
                        </div>
                    </div>
                </div>

            ) : (
                <>
                    <div className='container cont'>
                        <div className='bundle-container'>
                            <div className='bundle-container-img'>
                                <img className='bundle-img' src={bundle.image} alt={bundle.image_alt}/>
                            </div>
                            <div className='row justify-content-between align-items-center desc-container'>
                                <div className='col-12 col-lg-6 desc-container-right p-0'>
                                    <h1 className='desc-container-right-header'>{bundle.title}</h1>
                                    {/*<div className='desc-container-right-divider'/>*/}
                                </div>
                                <div className='col-5 col-lg-3 p-0 mt-4 mt-md-0'>
                                    <span
                                        className='desc-container-left-price-after'>{bundle.price_after} {t('EGP')}</span>
                                    <span
                                        className='desc-container-left-price-before'>{bundle.price_before} {t('EGP')}</span>
                                </div>
                                <div
                                    className='col row align-items-center p-0 desc-container-right-header-bundle-sticky-btns-section'>
                                    <div className='col mt-4 mt-md-0 '>
                                        <div className='my-modal-body-first-section-pricing'>
                                            <div className='my-modal-body-first-section-pricing-button-container'>
                                                <span className='button-container-buy'
                                                      >
                                                    <button
                                                        onClick={() => this.buyNow(bundle)}
                                                        disabled={this.state.buyRound}
                                                        className="btn button-container-buy-btn w-100">
                                                    {t("buy_now")}
                                                    </button>
                                                </span>
                                                <span className='button-container-add-to-cart'
                                                   >
                                                     <button className='w-100 h-100 p-0' style={{background:"unset",border:"none"}}
                                                             disabled={this.state.buyRound}
                                                             onClick={() => this.addToCart(bundle)}
                                                     >
                                                        <GrCart size='30' className="button-container-add-to-cart-icon"
                                                        />
                                                         </button>
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className='col-12 desc-container-left mt-4'>
                                    {/*<p className='desc-container-right-p'*/}
                                    {/*   dangerouslySetInnerHTML={{__html: bundle.description}}/>*/}
                                    {/*<h2 className='content-container-header'>{t("bundle_content")}</h2>*/}
                                    {/*<div className='desc-container-right-divider'/>*/}

                                    <div className='row'>
                                        {bundle.courses.map(course =>
                                            <div className='col-md-6 col-lg-3 py-3 px-1 content-container'
                                                 key={course.name} onClick={() => this.appearBriefCoursePopup(course)}>
                                                <div className='content-container-left'>
                                                    <div className='content-container-left-img-container'
                                                         style={{
                                                             backgroundImage: `linear-gradient(rgba(0, 0, 0, 0.2), 
                                                 rgba(0, 0, 0, 0.2)), url(${course.thumbnail_image ? course.thumbnail_image : course.image})`
                                                         }}>
                                                        <GrPlayFill size='30' color="white"
                                                                    className='content-container-left-img-container-play-icon'/>

                                                        {/*<img src={course.image} alt='img' className='content-container-left-img'/>*/}
                                                    </div>
                                                </div>
                                                <div className='content-container-center'>
                                                    <div
                                                        className='content-container-center-title mt-3'>{course.title}</div>
                                                </div>
                                                <div className='content-container-right'>

                                                    <div className=''><img src={star} alt="star_logo"/>
                                                        <span className='content-container-right-time'>
                                                    {moment.duration(`${course.course_houre}`, "minutes")
                                                        .format(`h [${t('hr')}]  m [${t('min')}]`, {trim: "both"})
                                                    }

                                                            {/*TODO: Add a new style for time.*/}
                                                            {/*        <BiTime size='15' fill='#908e8e'/>*/}
                                                            {/*    <span className='content-container-right-time'>*/}
                                                            {/*{moment.duration(`${course.course_houre}`, "minutes")*/}
                                                            {/*    .format(`h [${t('hr')}]  m [${t('min')}]`, {trim: "both"})*/}
                                                            {/*}*/}

                                               </span>
                                                    </div>
                                                    <div className='content-container-right-price mt-3'>
                                                 <span
                                                     className='content-container-right-price-before m-0'>{course.price_befor} {t('EGP')}</span>
                                                    </div>
                                                </div>
                                            </div>
                                        )}
                                        {appearBriefCourse &&
                                            <BriefCoursePopup appearPopup={appearBriefCourse}
                                                              disAppearPopup={this.disAppearPopup} course_id={course_id}
                                                              addToCart={this.addToCart}
                                                              buyNow={this.buyNow}
                                                              showButtons={false}
                                                              lang={lang}/>}
                                    </div>
                                    <h2 className='content-container-header'>{t("bundle_content")}</h2>
                                    {/*<div className='desc-container-right-divider'/>*/}
                                    <hr/>
                                    <p className='desc-container-right-p'>{nl2br(bundle.description)}</p>
                                </div>
                            </div>


                        </div>
                    </div>

                </>
            );
        return (<div>{rendered}
            <Helmet>
                <link href={"https://vol.variiance.com" + this.state.url} rel="canonical"/>
            </Helmet>
            <MetaTags>
                <title>{bundle.meta_title}</title>
                <meta name="description" content={bundle.meta_description}/>
                <meta content="IE=edge" httpEquiv="X-UA-Compatible"/>
                <meta property="og:type" content="website"/>
                <meta property="og:url" content={"https://vol.variiance.com" + this.state.url}/>
                <meta property="og:description" content={bundle.meta_description}/>
                <meta property="og:title" content={bundle.meta_title}/>
                <meta property="og:image" content={bundle.image}/>
                <meta property="og:image:alt" content={bundle.image_alt}/>
                <meta property="og:site_name" content="Vol"/>
                <meta content={bundle.meta_title} name="twitter:title"/>
                <meta content={bundle.meta_description} name="twitter:description"/>
                <meta content={bundle.image} name="twitter:image"/>
                <meta content="summary" name="twitter:card"/>
                <meta name="twitter:url" content={"https://vol.variiance.com" + this.state.url}/>
                <meta content="@Variianceglobal" name="twitter:site"/>
            </MetaTags>
        </div>)
    }
}

export default connect()(withRouter(withTranslation()(Bundle)));
