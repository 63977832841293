import React, {Component} from 'react';
import icon2 from '../imgs/group-6.png';
import icon3 from '../imgs/icon3.png';
import icon4 from '../imgs/icon2.png';
// import '../pages/all.css';
import {withTranslation} from 'react-i18next';
import {Link} from "react-router-dom";

class Singlecourseoverview extends Component {
    state = {
        alldata: this.props.prop1
    }

    // ToDo:: check this plz
    render() {
        const {t} = this.props;

        return (
            <>
                <div className="container">
                    <div className="row">
                        {/* <div className="col-lg-3  col-md-12">
                            <div className="mt-5 overviewfirst">
                                <img className="overviewicon" src={icon1}/>
                                <div className='overviewtxt'>
                                    <h5 className='text1'>4.7 Stars</h5>
                                    <p className='text2'> (87,805 ratings)</p>
                                </div>

                            </div>
                        </div> */}
                        {/*<div className="col-lg-4 col-md-12">*/}
                        {/*    <div className="mt-5 overviewfirst">*/}
                        {/*        <img className="overviewicon" alt="overviewicon" src={icon2}/>*/}
                        {/*        <div className='overviewtxt'>*/}
                        {/*            <h5 className='text1'>{t('Students')}</h5>*/}
                        {/*            <p className='text2'> {this.state.alldata.student_count} {t('Student')}</p>*/}
                        {/*        </div>*/}
                        {/*    </div>*/}
                        {/*</div>*/}
                        <div className="col-lg-3 col-md-12">
                            <Link to={'/singleteacher/' +this.state.alldata.rounds[0].first_name + '-' + this.state.alldata.rounds[0].last_name +'/'+this.state.alldata.rounds[0]?.instructor} style={{textDecoration: "none"}}>
                                <div className="mt-5 overviewfirst">
                                    <img className="overviewicon" alt="instructor" src={icon2}/>
                                    <div className="overviewtxt">
                                        <h5 className="text1">{t("Instructor")}</h5>
                                        <p className="text2"> {this.state.alldata.rounds[0] ? this.state.alldata.rounds[0].first_name + ' ' + this.state.alldata.rounds[0].last_name :null}</p>
                                    </div>
                                </div>
                            </Link>
                        </div>
                        <div className="col-lg-3  col-md-12">
                            <div className="mt-5 overviewfirst">
                                <img className="overviewicon" alt="learning method" src={icon3}/>
                                <div className="overviewtxt">
                                    <h5 className="text1">{t("Learning Method")}</h5>
                                    <p className="text2"> {this.state.alldata.rounds[0] ? this.state.alldata.rounds[0].delivery_method ===  "Online" ? 'Recorded' :this.state.alldata.rounds[0].delivery_method :null}</p>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-3 col-md-12">
                            <div className="mt-5 overviewfirst">
                                <img className="overviewicon" alt="Certificate" src={icon3}/>
                                <div className='overviewtxt'>
                                    <h5 className='text1'>{t('Certificate')}</h5>
                                    <p className='text2'>{t('Certificate of completion')} </p>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-3 col-md-12">
                            <div className="mt-5 overviewfirst">
                                <img className="overviewicon" alt="Cancellation policy" src={icon4}/>
                                <div className='overviewtxt'>
                                    <h5 className='text1'>{t('Cancellation policy')}</h5>
                                    <p className='text2'>{t('Flexible cancellation details')}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row my-3">
                        <div className="col-lg-12  col-md-12">
                            {/*<h3>{t('About')}</h3>*/}
                            <p className='text3'
                               dangerouslySetInnerHTML={{__html: this.state.alldata.course.overview}}></p>
                        </div>
                    </div>
                </div>
            </>
        );
    }
}

export default withTranslation()(Singlecourseoverview);