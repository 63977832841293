import React, {Component} from 'react';
import star from "../imgs/star.png"
import {Link, withRouter} from 'react-router-dom'
import axios from 'axios'
import Empty from '../components/empty'
import {Spin} from 'antd';
import {withTranslation} from 'react-i18next';
import "moment/locale/ar";
import "moment/locale/en-au";
import MetaTags from "react-meta-tags";
import NavBar from "./NavBar";

const momentDurationFormatSetup = require("moment-duration-format");
const moment = require("moment");

class Search extends Component {
  state = {
    isLoading: true,
    searchText: "",
    searchResults: [],
    results: [],
    pag: 0,
    total: null,
    per_page: 5,
    current_page: 1,
    parent: [],
    parentCat: [],
    lang: "",
    newQuery: "",
    metaTags: [],
    metatittle:'',
    data:[],
    removeClick:true

  };
  handleSearch = (e) => {
    let searchText = ''
    let route = ""
    let route1 = ""
    let set = ""

    let page = e
    let limit = 0
    if (this.props.match.params.cat === "t") {
      if (this.props.location.state !== undefined) {
        searchText = this.props.location.state.searchText;
        set += `q=${searchText}`
        limit = page * 5 - 5
        route1 = this.props.match.params.query
        let href = "/search/" + route1 + '/' + page + '/t'
        if(this.state.removeClick === true){
          this.props.history.push({
            pathname: href,
            state: {
              searchText: route1
            }

          });
        }



      } else {
        searchText = this.props.match.params.query
        set += `q=${searchText}`
        limit = page * 5 - 5
        route1 = this.props.match.params.query
        let href = "/search/" + route1 + '/' + page + '/t'
        if(this.state.removeClick === true){
        this.props.history.push({
          pathname: href,
          state: {
            searchText: route1
          }
        });}
      }
    } else if (this.props.match.params.query !== "") {
      if (this.props.match.params.cat === "p") {
        route = this.props.match.params.query
        set = `parent_category=${route}`
        limit = page * 5 - 5
        let href = "/search/" + route + '/' + page + '/p'
        if(this.state.removeClick === true){
        this.props.history.push({
          pathname: href,
          state: "yes"

        });}
        this.setState({parentCat: set})
      } else if (this.props.match.params.cat === "c") {
        route = this.props.match.params.query
        set = `category=${route}`
        limit = page * 5 - 5
        if(this.state.removeClick === true){
        let href = "/search/" + route + '/' + page + '/c'
        this.props.history.push({
          pathname: href,
        });}

      }
    }
    let lang = ""
    if (localStorage.getItem('i18nextLng') === "en" || localStorage.getItem('i18nextLng') === "en-US") {
      this.setState({lang: "en"})
      lang = "en"
    } else {
      this.setState({lang: "ar"})
      lang = "ar"
    }

    axios.get(process.env.REACT_APP_API_URL, {
      params: {
        // url: `/courses/${lang}/index?limit=5&page=${page}&${set}`
        url: `/api/method/variiance.vol.api.v2.courses.index?limit=5&page=${page}&${set}&lang=${lang}`
      }
    })
      .then(response => {

        this.setState({
          results: response.data.data.records,
          total: response.data.data.meta.total_count,
          current_page: page,
          pag: response.data.data.meta.pagination,
          isLoading: false,
          removeClick:true
        })
        this.topscroll()
      })
  }
  topscroll = (e) => {
    window.scrollTo(0, 0)
  };

  componentDidMount() {
    if (localStorage.getItem('i18nextLng') === "en" || localStorage.getItem('i18nextLng') === "en-US") {
      moment.locale('en-au');

    } else {
      moment.locale('ar');

    }
    this.handleCategory()
    this.handleQuery()
    this.setState({removeClick:false},()=>{
      this.handleSearch(this.props.match.params.page1);
    })


    let lang = "";
    if (
      localStorage.getItem("i18nextLng") === "en" ||
      localStorage.getItem("i18nextLng") === "en-US"
    ) {
      this.setState({lang: "en"});
      lang = "en";
    } else {
      this.setState({lang: "ar"});
      lang = "ar";
    }
    axios.get(process.env.REACT_APP_API_URL, {
      params: {
        url: "api/resource/VTSMetaTags/Categories_VOL_" + lang
      }
    }).then((response) => {
      let metaTags = response.data.data.meta_tags;
      let metatittle = response.data.data.page_title;
      this.setState({metaTags: metaTags})
      this.setState({metatittle: metatittle})

    });
  }


  handleQuery = (e) => {

    this.setState({newQuery: e}, () => {
      // this.setState({newQery:this.props.match.params.query})
      if (this.state.newQuery === this.props.match.params.query) {
        this.handleSearch(this.props.match.params.page1)

      }
    })
  }

  componentDidUpdate(prevProps) {
    let prevSearch = ''
    if (prevProps.location.state !== undefined) {
      prevSearch = prevProps.location.state.searchText
    }
    let newSearch = ''
    if (this.props.location.state !== undefined) {
      newSearch = this.props.location.state.searchText;
    }
    if (prevSearch !== newSearch && newSearch !== '' && prevSearch !== '') {
      this.handleSearch(this.props.match.params.page1);
      this.handleQuery()

    }
    if(prevProps.match.params.query!==this.props.match.params.query){
      this.handleSearch(this.props.match.params.page1);
      this.handleQuery()
    }
    if(prevProps.match.params.page1 !== this.props.match.params.page1){
      this.setState({removeClick:false},()=>{
        this.handleSearch(this.props.match.params.page1)
      })
    }
    // window.onpopstate  = (e) => {
    //     e.stopPropagation();
    //
    //
    //     if(this.props.match.params.cat ===prevProps.match.params.cat && this.props.match.params.cat==="c") {
    //         this.props.history.goBack()
    //
    //
    //         let page = this.props.match.params.page || 1 || this.props.match.params.name
    //         let limit = page * 5 - 5
    //         let set = this.state.parentCat
    //         const params = new URLSearchParams();
    //         let lang = ""
    //         if (localStorage.getItem('i18nextLng') === "en" || localStorage.getItem('i18nextLng') === "en-US") {
    //             this.setState({lang: "en"})
    //             lang = "en"
    //         } else {
    //             this.setState({lang: "ar"})
    //             lang = "ar"
    //         }
    //
    //         params.append('url', `/courses/${lang}/index?fields=["*"]&filters=[["is_published","=",1]${set}]&limit_page_length=5&limit_start=${limit}`);
    //         axios.post(process.env.REACT_APP_API_URL + "/dashboard", params)
    //             .then(response => {
    //
    //                 this.setState({
    //                     results: response.data.data.records,
    //                     total: response.data.data.meta.total_count,
    //                     current_page: page,
    //                     pag: response.data.data.meta.pagination,
    //                     isLoading: false,
    //                     parentCat:this.props.match.params.query
    //                 })
    //                 this.topscroll()
    //             })
    //         this.props.history.goBack()
    //
    //
    //     }else if(this.props.match.params.cat ===prevProps.match.params.cat && this.props.match.params.cat==="p"&& this.props.history.location.state==="yes" ){
    //         }
    //
    //
    // }


  }

  async handleCategory() {
    let lang = ""
    // this.setState({lang:"ar"})
    if (localStorage.getItem('i18nextLng') === "en" || localStorage.getItem('i18nextLng') === "en-US") {
      lang = "en"
    } else {
      lang = "ar"
    }
    await axios.get(process.env.REACT_APP_API_URL, {
      params: {
        // url: 'courses/' + lang + '/categories'
        url: `api/method/variiance.vol.api.v2.courses.categories?lang=${lang}`
      }

    })
        .then(response => {
          let data = response.data.data
          this.setState({data})

        })

  }
  render() {

    const {t} = this.props;
    let renderPageNumbers;
    const pageNumbers = [];
    if (this.state.total !== null) {
      for (let i = 1; i <= Math.ceil(this.state.total / this.state.per_page); i++) {
        pageNumbers.push(i);
      }
      renderPageNumbers = pageNumbers.map(number => {
        let classes = this.state.current_page == number ? "page-item page-item-active " : 'page-item ';

        return (
          <li className={classes} style={{cursor: "pointer"}} key={number}
              onClick={() => this.handleSearch(number)}>
            {number}
          </li>
        );
      });
    }
    const metaItems = this.state.metaTags.map((res) => {
      return(

          <meta key={res.key} name={res.key} content={res.value}/>

      )
    })
    const Items = this.state.data.map(res => {
      return (

          <div className="col-lg-2 col-md-4 col-xs-6 my-2" key={res.name}>
            <Link style={{textDecoration: "none", color: res.parent_category === decodeURIComponent(this.props.match.params.query) ? "#f2871e": "#908e8e"}}
                  to={"/search/" + encodeURIComponent(res.parent_category) + "/1" + "/p"}>
          <div className={res.parent_category === decodeURIComponent(this.props.match.params.query) ? "activeTabs":"TabsCategory"}data-aos="flip-left" data-aos-duration="3000" key={res.name1}>

                          <span>  {res.name1}</span>

                </div>
            </Link>
          </div>

      )

    })
    // TODO:: set search text from state
    let searchText = ''
    if (this.props.location.state !== undefined) {
      searchText = this.props.location.state.searchText;
    }
    let {results} = this.state
    let parent = ""
    let single = ""
    let singleCourse = results.map(res => {
      let method = []
      parent = res.parent_category_name
      single = res.category_name
      if (res.delivery_methods) {
        method = res.delivery_methods.map(res_method => {
          return <span className="spann" key={Math.random()}>{res_method}</span>
        });
      }
      return (
        <div className="row " key={res.name}>
          <div className="col-lg-12 my-3 mobcard " data-aos="fade-up" data-aos-duration="3000">
            <div className="row">
              <div className="col-lg-7 ">
                <div className="row">
                  <div className="col-lg-5 m-auto">
                    <Link className="removelink1"
                          to={"/course/" + encodeURIComponent(res.name)}> <img className="courseimg" alt={res.image_alt}
                                                                               style={{width: '210px', height: '150px'}}
                                                                               src={res.image}/>
                    </Link>
                  </div>
                  <div className=" col-lg-7 coursecontent ">
                    <h5><Link className="removelink1"
                              to={"/course/" + encodeURIComponent(res.name)}>{res.title}</Link>
                    </h5>
                    <p><Link className="removelink"
                             to={"/search/" + encodeURIComponent(res.category) + "/1" + "/c"}
                             onClick={() => this.handleQuery(res.category)}>{res.category_name}</Link>
                    </p>
                    {localStorage.getItem('i18nextLng') === "en" || localStorage.getItem('i18nextLng') === "en-US" ?
                    <div className="mb-1"><img src={star} alt="star icon"/> <span
                      className="spannn">{moment
                        .duration(res.course_houre, "minutes")
                        .format("h [hr]  m [min]", {trim: "both"})}</span>
                    </div>: <div className="mb-1"><img src={star} alt="star icon"/> <span
                            className="spannn">{moment
                            .duration(res.course_houre, "minutes")
                            .format("h [ساعة]  m [دقيقة]", {trim: "both"})}</span>
                        </div>

                    }

                    <div className="mb-1"><span>{method}</span></div>
                  </div>

                </div>
              </div>
              <div className=" courseprice col-lg-5 my-auto">
                {res.displayed_price_after < res.displayed_price_before ?
                    <h5>{res.displayed_price_after !== 0? res.displayed_price_after +" "+ t('EGP') : t('Free')}{" "}
                      <span style={{
                        textDecoration: "line-through",
                        fontSize: "14px",
                        color: "#908e8e",
                        margin:"0px 10px"
                      }}>{res.displayed_price_before +" "+ t('EGP')}</span>
                    </h5>: <h5>{res.displayed_price_after !== 0? res.displayed_price_after +" "+ t('EGP') : t('Free')}</h5>}
              </div>
            </div>


          </div>
        </div>

      )
      // this.setState({parent:res.parent_category})
    })
    let rendered = this.state.isLoading ?
      (
        <div className="container cont">
          <div className="row">
            <div className="col-lg-12 col-md-12">

              <div className="example">
                <Spin size="large" style={{margin: "auto"}}/>
              </div>
            </div>
          </div>
        </div>

      ) :
      (

        <>
          {this.state.results.length > 0 ? (
            <div className="container cont">
              <div className="row">
                <div className="mt-5 srchhead col-lg-12">
                  {searchText ?
                    <h1>{t("SearchResult")}<span style={{color: "#ee851d"}}> {searchText} </span>
                    </h1>
                    : this.props.match.params.cat === "p" ?
                      <h1>{t('Explore All For Catagory')} <span
                        style={{color: "#ee851d"}}> {parent} </span> {t('CoursesForCategory')}</h1> :
                      this.props.match.params.cat === "c" ?
                        <h1 className="mt-5">{t('Explore All')}
                          <span style={{color: "#ee851d"}}> {single} </span> {t('CoursesForCategory')}
                        </h1> : null

                  }

                  <hr/>
                </div>
              </div>
              <div className="CoursesTabs row ">

                <div className="col-lg-2 col-md-4 col-xs-6 my-2">
                  <Link to="/AllCourses/1" style={{textDecoration: "none", color: "#908e8e",cursor:"pointer"}}>
                               <div className="TabsCategory" data-aos="flip-left" data-aos-duration="3000">

                                {t('All Courses')}

                </div>
                  </Link>
                </div>

                {Items}
              </div>
              <div className="row mt-3">
                <div className="col-lg-9">

                  {singleCourse}
                </div>

                {/* <div className="col-lg-3 allfilter">
                                    {/*<Button className="copy-bg"><span*/}
                {/*    className="spanfilter">{t('Filter by')}</span></Button>*/}
                {/*<h1 className="Sessions-type">{t('Sessions type')}</h1>*/}
                {/*<Checkbox><span className="titleinput">{t('Online Session')}</span></Checkbox>*/}
                {/*<span className="titlefilter">500</span>*/}
                {/*<br/>*/}
                {/*<Checkbox><span className="titleinput">{t('Classroom')}</span> </Checkbox>*/}
                {/*<span className="titlefilter">900</span>*/}
                {/*<br/>*/}
                {/*<Checkbox><span className="titleinput">{t('Live Session')}</span></Checkbox>*/}
                {/*<span className="titlefilter">1300</span>*/}
                {/*<br/>
                                </div> */}

              </div>
              <div className="pb-5">
                <nav aria-label="Page navigation example ">
                  <ul className="pagination justify-content-left">
                    {/*<span onClick={() => this.makeHttpRequestWithPage(1)}>&laquo;</span>*/}
                    {renderPageNumbers}
                    {/*<span onClick={() => this.makeHttpRequestWithPage(1)}>&raquo;</span>*/}

                    {/*<li className="page-item page-item-active"><a*/}
                    {/*    style={{color: "#ffffff", textDecoration: "none", fontWeight: "700"}} href="#">1</a>*/}
                    {/*</li>*/}
                    {/*<li className="page-item"><a*/}
                    {/*    style={{color: "#33333a", textDecoration: "none", fontWeight: "700"}} href="#">2</a>*/}
                    {/*</li>*/}
                    {/*<li className="page-item"><a*/}
                    {/*    style={{color: "#33333a", textDecoration: "none", fontWeight: "700"}} href="#">3</a>*/}
                    {/*</li>*/}
                    {/*<li className="page-item"><a*/}
                    {/*    style={{color: "#33333a", textDecoration: "none", fontWeight: "700"}} href="#">4</a>*/}
                    {/*</li>*/}
                    {/*<li className="page-item"><a*/}
                    {/*    style={{color: "#33333a", textDecoration: "none", fontWeight: "700"}} href="#">5</a>*/}
                    {/*</li>*/}
                    <li
                      className={this.state.current_page == this.state.pag ? "nonee" : "page-item pag"}
                      style={{
                        color: "#908e8e",
                        textDecoration: "none",
                        fontWeight: "600",
                        cursor: "pointer"
                      }}
                      onClick={() => this.handleSearch(parseInt(this.props.match.params.page1) + 1)}>


                      {t('Next')}
                    </li>
                  </ul>
                </nav>
              </div>
            </div>

          ) : (
            <Empty/>
          )}

        </>
      )
    return (<div>
      <NavBar/>
      {rendered}
      <MetaTags>
        <title>{this.state.metatittle}</title>
        {metaItems}
      </MetaTags>
    </div>)
  }

}

export default withRouter(withTranslation()(Search));
