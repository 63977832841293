import React, {Component} from "react";
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
import axios from "axios";
import {Link} from "react-router-dom";
import {withTranslation} from "react-i18next";
import {GrCart} from "react-icons/all";
import BriefCoursePopup from "../pages/briefCoursePopup";
import keycloak from "../keycloak";
import {toast} from "react-toastify";
import * as types from "../redux/actions/actionTypes";
import {connect} from "react-redux";

const responsive = {
    superLargeDesktop: {
        // the naming can be any, depends on you.
        breakpoint: {max: 4000, min: 3000},
        items: 5
    },
    desktop: {
        breakpoint: {max: 3000, min: 1024},
        items: 3
    },
    tablet: {
        breakpoint: {max: 1024, min: 464},
        items: 2
    },
    mobile: {
        breakpoint: {max: 464, min: 0},
        items: 1
    }
};

class Slider extends Component {

    state = {
        courses: []
    };

    async componentDidMount() {
        let whichBtnPressed = localStorage.getItem('whichBtnPressed')
        let courseInCourses = localStorage.getItem('courseInHome')
        let lang = "";
        if (
            localStorage.getItem("i18nextLng") === "en" ||
            localStorage.getItem("i18nextLng") === "en-US"
        ) {
            this.setState({lang: "en"});
            lang = "en";
        } else {
            this.setState({lang: "ar"});
            lang = "ar";
        }
        await axios.get(process.env.REACT_APP_API_URL, {
            params: {
                url: "courses/" + lang + "/courses?is_featured=1"
            }
        })
            .then((response) => {
                let courses = response.data.data;

                this.setState({courses})

            }).then(() => {
                if (whichBtnPressed === 'buyNow') {
                    this.autoBuyNow(courseInCourses)
                } else if (whichBtnPressed === 'addToCart') {
                    this.autoAddToCart(courseInCourses)
                }
            });
    }


    autoAddToCart = async (course) => {
        if (keycloak && keycloak.authenticated) {
            await this.addToCart(course)
        } else {
            localStorage.removeItem('whichBtnPressed')
            localStorage.removeItem('courseInHome')
        }
    }

    autoBuyNow = async (course) => {
        if (keycloak && keycloak.authenticated) {
            await this.autoAddToCart(course)
            window.location.href = process.env.REACT_APP_PAYMENT + "/cartpage"
        }
        localStorage.removeItem('whichBtnPressed')
        localStorage.removeItem('courseInHome')
    }

    callAddToCartFunc = async (round, t) => {
        const roundObj = typeof round === "string" ? JSON.parse(round) : round

        let cart_items = [
            {
                "buyable_type": "Course Round",
                "buyable_id": roundObj.name,
                "price": roundObj.price_after,
                "discount": 0,
                "expiry_date": ""
            }
        ];

        const newparams = {
            // url: "payment_portal/ar/add_to_cart",
            url: "api/method/variiance.payment_app.api.v2.payment_portal.add_to_cart",
            data: {
                user_email: keycloak.tokenParsed.email,
                cart_items
            },
        };

        await axios.post(process.env.REACT_APP_API_URL, newparams).then((response) => {
            let status = response.data.data.status;
            if (status !== false) {
                toast.success(t("added"), {
                    position: toast.POSITION.TOP_RIGHT
                })
                this.props.dispatch({type: types.ADD_TO_CART, payload: {cartCount: 1}})
            } else {
                toast.error(t("itemExists"), {
                    position: toast.POSITION.TOP_RIGHT
                })

            }
        });
    }

    addToCart = async (round) => {
        const {t} = this.props
        if (keycloak && keycloak.authenticated) {
            await this.callAddToCartFunc(round, t)
            localStorage.removeItem('whichBtnPressed')
            localStorage.removeItem('courseInHome')
        } else {
            localStorage.setItem('whichBtnPressed', 'addToCart')
            localStorage.setItem('courseInHome', JSON.stringify(round))

            toast.error(t("pleaselogin"), {
                position: toast.POSITION.TOP_RIGHT
            })

            setTimeout(() => {
                keycloak.login()
            }, 2500);
        }
    };

    buyNow = async (round) => {
        const {t} = this.props
        if (keycloak && keycloak.authenticated) {
            await this.addToCart(round)
            localStorage.removeItem('whichBtnPressed')
            localStorage.removeItem('courseInHome')
            window.location.href = process.env.REACT_APP_PAYMENT + "/cartpage"
        } else {
            localStorage.setItem('whichBtnPressed', 'buyNow')
            localStorage.setItem('courseInHome', JSON.stringify(round))

            toast.error(t("pleaselogin"), {
                position: toast.POSITION.TOP_RIGHT
            })
            setTimeout(() => {
                keycloak.login()
            }, 2500);
        }
    }

    appearBriefCoursePopup = (course) => {
        this.setState({appearBriefCourse: true, course_id: course.name})
    }

    disAppearPopup = () => {
        this.setState({appearBriefCourse: false})
    }


    render() {
        const {t} = this.props;
        let {courses, appearBriefCourse, course_id, lang} = this.state
        let singleCourse = courses.map(course => {
            return (
                <div className="card2" key={course.name}>
                    <div>
                        <img className="cardimg" src={course.image} alt={course.image_alt}/>
                        <div className="row px-3 my-carousel-card-container">
                            <Link to={"/course/" + encodeURIComponent(course.name)} style={{textDecoration: "none"}}>
                                <span className="col-12">
                                    <h3 className="suptxt my-3">{course.title}</h3>
                                    <p className="head4 my-3">{course.description}</p>
                                </span>
                            </Link>
                            <div className='col-12 p-0 my-modal-body-first-section-pricing-button-container'
                                 onClick={() => this.appearBriefCoursePopup(course)}>
                                    <span className='button-container-buy m-2'>
                                        <button className="btn button-container-buy-btn">
                                            {t("View Course")}
                                        </button>
                                    </span>
                            </div>
                        </div>
                    </div>
                </div>
            )
        })

        return (
            <>
                <Carousel responsive={responsive}>
                    {singleCourse}
                </Carousel>
                {appearBriefCourse &&
                    <BriefCoursePopup appearPopup={appearBriefCourse}
                                      disAppearPopup={this.disAppearPopup} course_id={course_id}
                                      addToCart={this.addToCart}
                                      buyNow={this.buyNow}
                                      showButtons={true}
                                      lang={lang}/>}
            </>
        );
    }
}

export default connect()(withTranslation()(Slider));
