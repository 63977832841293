import React, {Component} from "react";
import axios from "axios";
import {Link, withRouter} from "react-router-dom";
import {withTranslation} from "react-i18next";
import "moment/locale/ar";
import "moment/locale/en-au";
import '../pages/all.css'


class Courses extends Component {
    constructor(props) {
        super(props);

        this.handleShowMoreSpecial = this.handleShowMoreSpecial.bind(this);
        this.handleShowMoreUpcoming = this.handleShowMoreUpcoming.bind(this);

        this.state = {
            special: [],
            upcoming: [],
            isLoading: true,
            specialLength: 3,
            specialLengthDefaultValue: 3,
            upcomingLength: 3,
            upcomingLengthDefaultValue: 3,
            noItemsspecial: "",
            noItemsUpcoming: "",
            courses:[]
        };
    }

    handleShowMoreSpecial() {
        this.setState({
            specialLength:
                this.state.specialLength >= this.state.special.length
                    ? this.state.specialLength
                    : this.state.special.length,
        });
    }

    handleShowMoreUpcoming() {
        this.setState({
            upcomingLength:
                this.state.upcomingLength >= this.state.upcoming.length
                    ? this.state.upcomingLength
                    : this.state.upcoming.length,
        });
    }

    handleShowless1 = () => {
        this.setState({
            upcomingLength: 3,
        });
    };
    handleShowless = () => {
        this.setState({
            specialLength: 3,
        });
    };

    async componentDidMount() {
        const {t} = this.props;
        let set = this.props.match.params.name;
        let lang = "";
        if (
          localStorage.getItem("i18nextLng") === "en" ||
          localStorage.getItem("i18nextLng") === "en-US"
        ) {
            this.setState({lang: "en"});
            lang = "en";
        } else {
            this.setState({lang: "ar"});
            lang = "ar";
        }
        await axios.get(process.env.REACT_APP_API_URL, {
            params: {
                // url: "courses/" + lang + "/courses?instructor=" + set
                url: `/api/method/variiance.vol.api.v2.courses.courses?instructor=${set}&is_featured=1&lang=${lang}
`
            }
        })
          .then((response) => {
              let courses = response.data.data;
              this.setState({courses})

          });

    }

    render() {
        const {t} = this.props;
        const {courses}= this.state
        let singleCourse = courses.map(res=>{
            return(
                <div className="col-lg-4 mobpage col-md-7" key={res.name}>
                    <Link to={"/course/" + encodeURIComponent(res.name)} style={{textDecoration:"none"}}>

                    <div className="card2">

                        <div>
                            <img className="cardimg" src={res.image} alt={res.image_alt}/>
                            <div className="px-3">
                                <h4 className="suptxt my-3">{res.title}</h4>
                                <p className="head4">{res.description}</p>

                            </div>
                        </div>
                    </div>
                    </Link>
                </div>
            )
        })

        return (
            <>
                <div className="container aboutinfo">
                    <div className="row">

                        {singleCourse}

                    </div>
                </div>
            </>
        );
    }
}

export default withRouter(withTranslation()(Courses));
