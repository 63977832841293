import React, {Component} from "react";
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
import axios from "axios";
import {Link, withRouter} from "react-router-dom";
import {withTranslation} from "react-i18next";
import keycloak from "../keycloak";
import {toast} from "react-toastify";
import moment from "moment";
import * as types from "../redux/actions/actionTypes";
import {GrCart} from "react-icons/all";
import {connect} from "react-redux";

const responsive = {
    superLargeDesktop: {
        // the naming can be any, depends on you.
        breakpoint: {max: 4000, min: 3000},
        items: 5
    },
    desktop: {
        breakpoint: {max: 3000, min: 1024},
        items: 3
    },
    tablet: {
        breakpoint: {max: 1024, min: 464},
        items: 2
    },
    mobile: {
        breakpoint: {max: 464, min: 0},
        items: 1
    }
};

class BundleSlider extends Component {

    state = {
        bundles: [],
        buyRound:false
    };

    async componentDidMount() {
        let whichBtnPressed = localStorage.getItem('whichBtnPressed')
        let bundleInHome = localStorage.getItem('bundleInHome')

        let lang = ""
        if (localStorage.getItem('i18nextLng') === "en" || localStorage.getItem('i18nextLng') === "en-US") {
            lang = "en"
            moment.locale('en-au');
        } else {
            lang = "ar"
            moment.locale('ar');
        }

        await axios.get(process.env.REACT_APP_API_URL, {
            params: {
                // url: `api/method/variiance.vol.api.v2.bundles.get_all_bundles?lang=${lang}`
                url: `api/method/variiance.vol.api.v2.bundles.popular_bundles?page=1&lang=${lang}`

            }
        })
            .then((response) => {
                // let bundles = response.data.message.filter(best_bundle => best_bundle.is_pubular === 1);
                let bundles = response.data.message;
                this.setState({bundles})

            }).then(() => {
                if (whichBtnPressed === 'buyNow') {
                    this.autoBuyNow(bundleInHome)
                } else if (whichBtnPressed === 'addToCart') {
                    this.autoAddToCart(bundleInHome)
                }
            });


        /*if (whichBtnPressed === 'buyNow') {
            this.buyNow()
        }*/
    }

    autoAddToCart = async (bundle) => {
        if (keycloak && keycloak.authenticated) {
            await this.addToCart(bundle)
        } else {
            localStorage.removeItem('whichBtnPressed')
            localStorage.removeItem('bundleInHome')
        }
    }

    autoBuyNow = async (bundle) => {
        if (keycloak && keycloak.authenticated) {
            await this.autoAddToCart(bundle)
            window.location.href = process.env.REACT_APP_PAYMENT + "/cartpage"
        }
        localStorage.removeItem('whichBtnPressed')
        localStorage.removeItem('bundleInHome')
    }

    callAddToCartFunc = async (bundle, t) => {
        const bundleObj = typeof bundle === "string" ? JSON.parse(bundle) : bundle
        this.setState({buyRound:true})
        let buyingBundle = [{
            buyable_type: "Bundles",
            buyable_id: bundleObj.name,
            price: bundleObj.price_after,
            discount: 0,
        }];

        const newparams = {
            // url: "payment_portal/ar/add_to_cart",
            url: "api/method/variiance.payment_app.api.v2.payment_portal.add_to_cart",
            data: {
                user_email: keycloak.tokenParsed.email,
                cart_items: buyingBundle,
                expiry_date: bundleObj.bundle_expire_on
            },
        };

        await axios.post(process.env.REACT_APP_API_URL, newparams)
            .then((response) => {
                let status = response.data.data.status;
                if (status !== false) {
                    this.setState({selectedProduct: [], subTotal: 0});
                    toast.warning(t("added"), {
                        position: toast.POSITION.TOP_RIGHT
                    })
                    this.setState({buyRound:false})

                    this.props.dispatch({type: types.ADD_TO_CART, payload: {cartCount: 1}})
                    // setInterval(() => {
                    //     window.location.reload();
                    // }, 600);
                } else {
                    this.setState({buyRound:false})
                    toast.error(t("itemExists"), {
                        position: toast.POSITION.TOP_RIGHT
                    })

                }
            })
    }

    addToCart = async (bundle) => {
        const {t} = this.props
        if (keycloak && keycloak.authenticated) {
            this.setState({buyRound:true})
            await this.callAddToCartFunc(bundle, t)
            localStorage.removeItem('whichBtnPressed')
            localStorage.removeItem('bundleInHome')
        } else {
            localStorage.setItem('whichBtnPressed', 'addToCart')
            localStorage.setItem('bundleInHome', JSON.stringify(bundle))

            toast.error(t("pleaselogin"), {
                position: toast.POSITION.TOP_RIGHT
            })

            setTimeout(() => {
                keycloak.login()
            }, 2500);
        }
    };

    buyNow = async (bundle) => {
        const {t} = this.props
        if (keycloak && keycloak.authenticated) {
            this.setState({buyRound:true})
            await this.addToCart(bundle)
            localStorage.removeItem('whichBtnPressed')
            localStorage.removeItem('bundleInHome')
            window.location.href = process.env.REACT_APP_PAYMENT + "/cartpage"
        } else {
            localStorage.setItem('whichBtnPressed', 'buyNow')
            localStorage.setItem('bundleInHome', JSON.stringify(bundle))

            toast.error(t("pleaselogin"), {
                position: toast.POSITION.TOP_RIGHT
            })
            setTimeout(() => {
                keycloak.login()
            }, 2500);
        }
    }

    render() {
        const {t} = this.props;
        const {bundles} = this.state
        let singleBundle = bundles.map(bundle => {
            return (
                <div className="card2 my-carousel-card-container" key={bundle.name}>
                    <img className="cardimg" src={bundle.image} alt={bundle.image_alt}/>
                    <Link to={"/bundle/" + encodeURIComponent(bundle.name)}
                          style={{textDecoration: "none"}}>
                        <h3 className="bundles-title px-3 mt-3">{bundle.title}</h3>
                    </Link>
                    <p className="bundles-desc px-3">{bundle.description}</p>
                    <div className="row mb-1 px-3">
                        <div className='col-12 fs-6 content-container-right-price'>
                                    <span
                                        className='content-container-right-price-after'>{bundle.price_after}
                                        {t('EGP')}</span>
                            <span
                                className='content-container-right-price-before'>{bundle.price_before}
                                {t('EGP')}</span>
                        </div>
                        <div className='col my-modal-body-first-section-pricing-button-container'>
                                    <span className='button-container-add-to-cart'
                                         >
                                       <button style={{background:"unset",border:"none"}}
                                               disabled={this.state.buyRound}
                                            onClick={() => this.addToCart(bundle)}>
                                         <GrCart size='30' className="button-container-add-to-cart-icon"
                                         /></button>
                                    </span>
                            <span className='button-container-buy me-0'
                                  >
                                        <button className="btn button-container-buy-btn"
                                        disabled={this.state.buyRound}
                                                onClick={() => this.buyNow(bundle)}>
                                            {t("buy_now")}
                                        </button>
                                    </span>
                        </div>
                    </div>
                </div>
            )
        })

        return (
            <>
                <Carousel responsive={responsive}>

                    {singleBundle}

                </Carousel>
            </>
        );
    }
}

export default connect()(withRouter(withTranslation()(BundleSlider)));
