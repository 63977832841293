import React from "react";
import logo1 from "../imgs/vol_logo.jpg";
import avatar1 from "../imgs/256-512.png";
import cartico from "../imgs/cart-1-1.svg";
import {NavLink, withRouter} from "react-router-dom";
import {Badge} from "antd";
import keycloak from "./../keycloak";
import axios from "axios";
import {NavHashLink} from "react-router-hash-link";
import Lang from "../components/lang";
import {DropdownItem, DropdownMenu, DropdownToggle, UncontrolledDropdown,} from "reactstrap";
import * as Icon from "react-feather";
import {withTranslation} from "react-i18next";
import {IoMdArrowDropdown} from "react-icons/all";
import HeaderBanner from "./HeaderBanner";
import {connect} from "react-redux";
import * as types from "../redux/actions/actionTypes";


const onRemoveClick = (e) => {
    document.getElementById("navbarSupportedContent").classList.remove("show");
};

class NavBar extends React.Component {
    state = {
        count: ""
    }

    componentDidMount() {
        if (keycloak && keycloak.authenticated) {
            axios.get(process.env.REACT_APP_API_URL, {
                params: {
                  // url: "payment_portal/ar/cart_count/" + keycloak.tokenParsed.email
                  url: "api/method/variiance.payment_app.api.v2.payment_portal.cart_count?email=" + keycloak.tokenParsed.email,
                }
            })
                .then((response) => {
                    let count = response.data.data;
                    // this.setState({count});
                    this.props.dispatch({type: types.ADD_TO_CART, payload: {cartCount: count}})
                });
        }
        // this.setState({ searchText: getParameterByName("q") });
    }

    render() {
        const {t} = this.props;

        return (<>
            <div className="fixed-top">
                <HeaderBanner/>
                <nav className="navbar navbar-expand-lg navbar-light">
                    <div className="container-fluid navcont">
                        <div>
                            <button
                                className="navbar-toggler"
                                type="button"
                                data-bs-toggle="collapse"
                                data-bs-target="#navbarSupportedContent"
                                aria-controls="navbarSupportedContent"
                                aria-expanded="false"
                                aria-label="Toggle navigation"
                            >
                                <span className="navbar-toggler-icon"></span>
                            </button>
                            <NavLink className="navbar-brand  my-auto" to="/">
                                {" "}
                                <img src={logo1} alt="logo variiance" className='logo-nav' width="40"/>
                            </NavLink>
                        </div>

                        <div
                            className="collapse navbar-collapse"
                            id="navbarSupportedContent"
                        >
                            <ul className="navbar-nav customclass ">
                                <li
                                    className="nav-item"
                                    data-toggle="collapse"
                                    data-target=".navbar-collapse.show"
                                >
                                    <NavHashLink smooth
                                                 to="/#home"
                                                 exact={true}
                                                 activeClassName="act"
                                                 className="btn btn1 btn2 "
                                                 onClick={onRemoveClick}
                                    >
                                        {" "}
                                        {t("Home")}
                                    </NavHashLink>

                                </li>


                                {/*<li*/}
                                {/*    className="nav-item"*/}
                                {/*    data-toggle="collapse"*/}
                                {/*    data-target=".navbar-collapse.show"*/}
                                {/*>*/}
                                {/*    <NavHashLink smooth*/}
                                {/*                 to="/#about"*/}
                                {/*                 activeClassName="act"*/}
                                {/*                 className="btn btn1 btn2 "*/}
                                {/*                 onClick={onRemoveClick}*/}
                                {/*    >*/}
                                {/*        {" "}*/}
                                {/*        {t("About")}*/}
                                {/*    </NavHashLink>*/}


                                {/*</li>*/}


                                {/*<li*/}
                                {/*    className="nav-item"*/}
                                {/*    data-toggle="collapse"*/}
                                {/*    data-target=".navbar-collapse.show"*/}
                                {/*>*/}
                                {/*    <NavHashLink smooth*/}
                                {/*                 to="/#Features"*/}
                                {/*                 activeClassName="act"*/}
                                {/*                 className="btn btn1 btn2 "*/}
                                {/*                 onClick={onRemoveClick}*/}
                                {/*    >*/}
                                {/*        {" "}*/}
                                {/*        {t("Features")}*/}

                                {/*    </NavHashLink>*/}
                                {/*</li>*/}

                                {/*<li*/}
                                {/*    className="nav-item"*/}
                                {/*    data-toggle="collapse"*/}
                                {/*    data-target=".navbar-collapse.show"*/}
                                {/*>*/}
                                {/*    <NavHashLink smooth*/}
                                {/*                 to="/#vtalks"*/}
                                {/*                 activeClassName="act"*/}
                                {/*                 className="btn btn1 btn2"*/}
                                {/*                 onClick={onRemoveClick}*/}
                                {/*    >*/}
                                {/*        {" "}*/}
                                {/*        {t("V-Talks")}*/}
                                {/*    </NavHashLink>*/}


                                {/*</li>*/}

                                <li
                                    className="nav-item"
                                    data-toggle="collapse"
                                    data-target=".navbar-collapse.show"
                                >
                                    <NavHashLink smooth
                                                 to="/AllCourses/1"
                                                 exact={true}
                                                 activeClassName="act"
                                                 className="btn btn1 btn2 "

                                    >
                                        {" "}
                                        {t("All courses")}
                                    </NavHashLink>

                                </li>

                                <li
                                    className="nav-item"
                                    data-toggle="collapse"
                                    data-target=".navbar-collapse.show"
                                >
                                    <NavHashLink smooth
                                                 to="/bundles/"
                                                 exact={true}
                                                 activeClassName="act"
                                                 className="btn btn1 btn2 "

                                    >
                                        {" "}
                                        {t("all-courses-bundles")}
                                    </NavHashLink>
                                </li>
                            </ul>

                            <div className=" ulmob mx-2 ">
                                <Lang/>
                            </div>
                        </div>
                        <div className="row justify-content-around align-items-center" style={{width: !keycloak.authenticated ? '14rem' : 'auto'}}>
                            <a href={process.env.REACT_APP_PAYMENT + "/cartpage"} className='col-4'>
                                <Badge count={this.props.cartReduxState.cart} showZero>
                                    <img
                                        className="ml-2"
                                        style={{width: "25px", height: "24px"}}
                                        alt="cart icon"
                                        src={cartico}
                                    />
                                </Badge>
                            </a>

                            {keycloak && !keycloak.authenticated ? (<div className="col-5 bg-btn1">
                                <button
                                    className="Label-btn1"
                                    onClick={() => keycloak && !keycloak.authenticated ?
                                        keycloak.login() : keycloak.logout()}>
                                    {keycloak && !keycloak.authenticated ? t("Login") : t("Logout")}
                                </button>
                            </div>) : (<ul
                                className="col-7 ulmob nav navbar-nav navbar-nav-user">
                                <UncontrolledDropdown
                                    tag="li"
                                    className="dropdown-user nav-item">
                                    <DropdownToggle
                                        tag="a"
                                        className="nav-link dropdown-user-link user-menu"
                                        >

                                        <span data-tour="user">
                                          <img
                                              className="pic ml-5 user-menu-img"
                                              alt="profile"
                                              src={
                                                  keycloak.tokenParsed.image
                                                      ? keycloak.tokenParsed.image
                                                      : avatar1
                                              }
                                          />
                                        </span>
                                        {/*<span className="mx-1 user-menu-name">*/}
                                        {/*            {keycloak.tokenParsed.given_name}*/}
                                        {/*        </span>*/}


                                    </DropdownToggle>
                                    <DropdownMenu right>
                                        <DropdownItem
                                            tag="a"
                                            href={process.env.REACT_APP_PROFILE}>
                                            <Icon.User size={14} className="mr-50"/>
                                            <span className="align-middle m-2">
                                                        {" "}
                                                {keycloak.tokenParsed.name}{" "}
                                                    </span>
                                        </DropdownItem>
                                        {keycloak.tokenParsed.resource_access.website
                                            .roles.includes("instructors") === true ?
                                            <DropdownItem
                                                tag="a"
                                                href={process.env.REACT_APP_INSTRUCTOR}
                                            >
                                                <Icon.Linkedin size={14} className="mr-50"/>
                                                <span className="align-middle m-2">
                                                        {" "}
                                                    {t("instructor dashboard")}
                                                    </span>
                                            </DropdownItem> : null}
                                        <DropdownItem
                                            tag="a"
                                            href={process.env.REACT_APP_PARTICIPANT}
                                        >
                                            <Icon.UserCheck size={14} className="mr-50"/>
                                            <span className="align-middle m-2">
                                                        {" "}
                                                {t("participant dashboard")}{" "}
                                                    </span>
                                        </DropdownItem>
                                        <DropdownItem
                                            tag="a"
                                            href={process.env.REACT_APP_PAYMENT}
                                        >
                                            <Icon.CreditCard size={14} className="mr-50"/>
                                            <span className="align-middle m-2">
                                                        {" "}
                                                {t("payment portal")}{" "}
                                                    </span>
                                        </DropdownItem>
                                        {/*<DropdownItem*/}
                                        {/*    tag="a"*/}
                                        {/*    href={process.env.REACT_APP_VTS}*/}
                                        {/*>*/}
                                        {/*    <Icon.Book size={14} className="mr-50"/>*/}
                                        {/*    <span className="align-middle m-2">*/}
                                        {/*                {" "}*/}
                                        {/*        {t("Vts")}{" "}*/}
                                        {/*            </span>*/}
                                        {/*</DropdownItem>*/}
                                        {/*<DropdownItem tag="a" href={process.env.REACT_APP_VLC}>*/}
                                        {/*    <Icon.Wifi size={14} className="mr-50"/>*/}
                                        {/*    <span className="align-middle m-2">*/}
                                        {/*                {" "}*/}
                                        {/*        {t("VLC")}{" "}*/}
                                        {/*            </span>*/}
                                        {/*</DropdownItem>*/}

                                        <DropdownItem divider/>

                                        <DropdownItem
                                            tag="a"
                                            onClick={() => {
                                                localStorage.removeItem('student_id')
                                                keycloak.logout()
                                            }}
                                            className="btn-link align-middle"
                                        >
                                            <Icon.Power size={14} className="mr-50"/>
                                            <span
                                                className=" align-middle"
                                                onClick={() => {
                                                    localStorage.removeItem('student_id')
                                                    keycloak.logout()
                                                }}
                                            >
                                                        {" "}
                                                {t("Logout")}{" "}
                                                    </span>
                                        </DropdownItem>
                                    </DropdownMenu>
                                </UncontrolledDropdown>
                            </ul>)}
                        </div>
                    </div>
                </nav>
            </div>
        </>);
    }
}


const mapStateToProps = (reduxState) => ({
    cartReduxState: reduxState
})


export default connect(mapStateToProps)(withRouter(withTranslation()(NavBar)));
