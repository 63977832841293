import React, {Component} from "react";
import {Button} from "antd";
import {withTranslation} from "react-i18next";

class About extends Component {
  state = {
    alldata: this.props.prop1,
  };

  render() {
    const {t} = this.props;
    let {alldata} = this.state;

    return (
      <div className="row">

        <div className="row">
          <div className="col-lg-12 col-md-12 aboutinfo">
            <p className="head">{t("About Instructor")}</p>
            <p className="headpar">{alldata.description}</p>
          </div>
        </div>
      </div>
    );
  }
}

export default withTranslation()(About);
