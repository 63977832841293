import React, {Component} from 'react';
import {Link, withRouter} from 'react-router-dom';
import axios from 'axios'
import {withTranslation} from 'react-i18next';
import {Spin} from "antd";
import MetaTags from "react-meta-tags";
import {FaRegBookmark} from "react-icons/fa";
import Empty from "../components/empty";
import {Helmet} from "react-helmet";
import keycloak from "../keycloak";
import {toast} from "react-toastify";
import {connect} from "react-redux";
import * as types from "../redux/actions/actionTypes";
import {GrCart} from "react-icons/all";
import bundle from "./bundle";

class Bundles extends Component {
    state = {
        data: [],
        lang: "",
        isLoading: true,
        metatittle: '',
        searchText: "",
        searchResults: [],
        bundles: [],
        pag: 0,
        total: null,
        per_page: 5,
        current_page: 1, // parent: [],
        parentCat: [],
        newQuery: "",
        removeClick: true,
        metaItemsName: [],
        metaItemsProperty: [],
        url: "",
        buyRound:false,
    }

    async componentDidMount() {
        this.setState({url: this.props.match.url})
        let whichBtnPressed = localStorage.getItem('whichBtnPressed')
        let bundleInBundles = localStorage.getItem('bundleInBundles')

        let lang = "";
        if (localStorage.getItem("i18nextLng") === "en" || localStorage.getItem("i18nextLng") === "en-US") {
            this.setState({lang: "en"});
            lang = "en";
        } else {
            this.setState({lang: "ar"});
            lang = "ar";
        }

        await axios.get(process.env.REACT_APP_API_URL, {
            params: {
                url: `api/method/variiance.vol.api.v2.bundles.get_all_bundles?page=1&lang=${lang}`
            }
        })
            .then(res => {
                this.setState({
                    bundles: res.data.message,
                    isLoading: false,
                })
            }).then(() => {
                if (whichBtnPressed === 'buyNow') {
                    this.autoBuyNow(bundleInBundles)
                } else if (whichBtnPressed === 'addToCart') {
                    this.autoAddToCart(bundleInBundles)
                }
            })

        await axios.get(process.env.REACT_APP_API_URL, {
            params: {
                url: "api/resource/VTSMetaTags/Bundles_VOL_" + lang
            }
        }).then((response) => {
            let metaItemsName = response.data.data.meta_tags.filter((res) => {
                if (res.type === "name") {
                    return res
                }
            })
            let metaItemsProperty = response.data.data.meta_tags.filter((res) => {
                if (res.type === "property") {
                    return res
                }
            })
            this.setState({metaItemsName, metaItemsProperty})
            let metatittle = response.data.data.page_title;
            this.setState({metatittle: metatittle})
        });
    }

    autoAddToCart = async (bundle) => {
        if (keycloak && keycloak.authenticated) {
            await this.addToCart(bundle)
        } else {
            localStorage.removeItem('whichBtnPressed')
            localStorage.removeItem('bundleInBundles')
        }
    }

    autoBuyNow = async (bundle) => {
        if (keycloak && keycloak.authenticated) {
            await this.autoAddToCart(bundle)
            window.location.href = process.env.REACT_APP_PAYMENT + "/cartpage"
        }
        localStorage.removeItem('whichBtnPressed')
        localStorage.removeItem('bundleInBundles')
    }

    callAddToCartFunc = async (bundle, t) => {
        const bundleObj = typeof bundle === "string" ? JSON.parse(bundle) : bundle
        this.setState({buyRound:true})

        let buyingBundle = [{
            buyable_type: "Bundles",
            buyable_id: bundleObj.name,
            price: bundleObj.price_after,
            discount: 0,
        }];

        const newparams = {
            // url: "payment_portal/ar/add_to_cart",
            url: "api/method/variiance.payment_app.api.v2.payment_portal.add_to_cart",
            data: {
                user_email: keycloak.tokenParsed.email,
                cart_items: buyingBundle,
                expiry_date: bundleObj.bundle_expire_on
            },
        };

        await axios.post(process.env.REACT_APP_API_URL, newparams)
            .then((response) => {
                let status = response.data.data.status;
                if (status !== false) {
                    this.setState({selectedProduct: [], subTotal: 0});
                    toast.warning(t("added"), {
                        position: toast.POSITION.TOP_RIGHT
                    })
                    this.setState({buyRound:false})
                    this.props.dispatch({type: types.ADD_TO_CART, payload: {cartCount: 1}})
                    // setInterval(() => {
                    //     window.location.reload();
                    // }, 600);
                } else {
                    this.setState({buyRound:false})
                    toast.error(t("itemExists"), {
                        position: toast.POSITION.TOP_RIGHT
                    })

                }
            })
    }

    addToCart = async (bundle) => {
        const {t} = this.props
        if (keycloak && keycloak.authenticated) {
            this.setState({buyRound:true})
            await this.callAddToCartFunc(bundle, t)
            localStorage.removeItem('whichBtnPressed')
            localStorage.removeItem('bundleInBundles')
        } else {
            localStorage.setItem('whichBtnPressed', 'addToCart')
            localStorage.setItem('bundleInBundles', JSON.stringify(bundle))

            toast.error(t("pleaselogin"), {
                position: toast.POSITION.TOP_RIGHT
            })

            setTimeout(() => {
                keycloak.login()
            }, 2500);
        }
    };

    buyNow = async (bundle) => {
        const {t} = this.props
        if (keycloak && keycloak.authenticated) {
            this.setState({buyRound:true})
            await this.addToCart(bundle)
            localStorage.removeItem('whichBtnPressed')
            localStorage.removeItem('bundleInBundles')
            window.location.href = process.env.REACT_APP_PAYMENT + "/cartpage"
        } else {
            localStorage.setItem('whichBtnPressed', 'buyNow')
            localStorage.setItem('bundleInBundles', JSON.stringify(bundle))

            toast.error(t("pleaselogin"), {
                position: toast.POSITION.TOP_RIGHT
            })
            setTimeout(() => {
                keycloak.login()
            }, 2500);
        }
    }

    render() {
        const {t} = this.props;

        let singleMetaName = this.state.metaItemsName.map(res => {
            return <meta key={res.key} name={res.key} content={res.value}/>
        })
        let singleMetaProperty = this.state.metaItemsProperty.map(res => {
            return <meta key={res.key} property={res.key} content={res.value}/>
        })
        let {bundles} = this.state
        let singleBundle = bundles.map(bundle => {
            return (<div key={bundle.name}>
                <div className="col row flex-column justify-content-between p-2 bundle-card m-3"
                     data-aos="fade-up" data-aos-duration="3000">
                            <span className='bundle-container'>
                                <img className=""
                                     alt={bundle.image_alt}
                                     style={{
                                         width: '100%', height: '150px',
                                         objectFit: 'cover',
                                         objectPosition: 'center'
                                     }}
                                     src={bundle.image}/>
                            </span>
                    <Link className="removelink1" to={"/bundle/" + encodeURIComponent(bundle.name)}>
                        <h4 className="bundles-title mt-2">
                            {bundle.title}
                        </h4>
                        <p className="bundles-desc">{bundle.description}</p>
                    </Link>

                    <div className="mt-2">
                        <div className='col-12'>
                            {/*<FaRegBookmark className='book-mark'/>*/}
                            <span>{t('Courses') + bundle.courses_count}</span>
                        </div>

                        <div className='col-12 fs-6 content-container-right-price'>

                                <span className='content-container-right-price-after'>
                                    {bundle.price_after} {t('EGP')}
                                </span>
                            <span
                                className='content-container-right-price-before'>
                                    {bundle.price_before} {t('EGP')}
                                </span>
                        </div>
                        <div className='row justify-content-between mt-2 desc-container-left'>
                            <div className='my-modal-body-first-section-pricing-button-container'>
                                <span className='button-container-buy' >
                                    <button className="btn button-container-buy-btn w-100"
                                            disabled={this.state.buyRound}
                                            onClick={() => this.buyNow(bundle)}
                                    >
                                    {t("buy_now")}
                                    </button>
                                </span>
                                <span className='button-container-add-to-cart'
                                     >
                                       <button className='w-100 h-100 p-0' style={{background:"unset",border:"none"}}
                                               disabled={this.state.buyRound}
                                               onClick={() => this.addToCart(bundle)}>
                                        <GrCart size='30' className="button-container-add-to-cart-icon"
                                        /></button>
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>)
        })

        let rendered = this.state.isLoading ? (<div className="container cont">
                <div className="row">
                    <div className="col-lg-12 col-md-12">

                        <div className="example">
                            <Spin size="large" style={{margin: "auto"}}/>
                        </div>
                    </div>
                </div>
            </div>

        ) : (<>
                {this.state.bundles.length > 0 ? (<div className="container cont">
                    <div className="row">
                        <div className="srchhead col-lg-12">
                            <h1 className="mt-1">{t('Explore All')} <span
                                style={{color: "#ee851d"}}>{t('Bundles')}</span></h1>
                            <hr/>
                        </div>
                    </div>

                    <div className="row">
                        <div className="container">
                            <div className="row row-cols-1 row-cols-sm-2 row-cols-lg-3 m-3">
                                {singleBundle}
                            </div>
                        </div>
                    </div>

                </div>) : (<Empty/>)}
            </>

        );
        return (<div>{rendered}
            <Helmet>
                <link href={"https://vol.variiance.com" + this.state.url} rel="canonical"/>
            </Helmet>
            <MetaTags>
                <title>{this.state.metatittle}</title>
                {singleMetaName}
                {singleMetaProperty}
            </MetaTags>
        </div>)
    }
}

export default connect()(withRouter(withTranslation()(Bundles)));
