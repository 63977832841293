import React, {Component} from 'react';

class IframeCart extends Component {
    render() {
        return (
            <div className='my-iframe-container'>
                {/*<iframe src={"http://localhost:3001/cartpage?hideMenu=1"}/>*/}
                <iframe src={process.env.REACT_APP_PAYMENT + "/cartpage?hideMenu=1"}/>
            </div>
        );
    }
}

export default IframeCart;