import React from "react";
import "./App.css";
import {Route, Router, Switch} from "react-router-dom";
//pages
import {useTranslation} from "react-i18next";

import {useKeycloak} from "@react-keycloak/web";
import {history} from "./history";
import NavBar from "./pages/NavBar.js";
import Categoris from "./pages/Categoris.js";
import Search from "./pages/search.js";
import Course from "./pages/course.js";
import Empty from "./components/empty";
import Footer from "./pages/footer";
import ScrollToTop from "./pages/ScrollToTop";
import Singleteacher from "./pages/singleteacher";
import NavBarHome from "./pages/NavHome";
import Home from "./pages/Home";
import axios from "axios";
import keycloak from "./keycloak";
import Cookies from "universal-cookie";
import {ExamplePage} from "./pages/chatBot";
import AllCourses from "./pages/AllCourses";
import EmptyRedirection from "./pages/EmptyRedirection";
import NotFound from "./components/Error404";
import EmptyRedirectionRefaller from "./pages/EmptyRedirectionRefaller";
import EmptyRedirectionRefallerGeneral from "./pages/EmptyRedirectionRefallerGeneral";
import AuthSearch from "./pages/AuthSearch";
import AuthAllCourses from "./pages/AuthAllCourses";
import Bundles from "./pages/Bundles";
import Bundle from "./pages/bundle";
import IframeCart from "./pages/IframeCart";

const cookies = new Cookies();


// if (dir==="ltr") {
// import('./pages/all.css')
// }else{
//     import('./pages/all.rtl.css')
// }
// const guardAuth = (Component) => () => {
//
//     return keycloak && !keycloak.authenticated ? (
//
//         keycloak.login()
//     ) : (
//         <Component/>
//     );
// };

function getUser() {
    const params = {
        // url: "profile/ar/get_user",
        url: "/api/method/variiance.users.api.v2.profile.get_user",
        data: {
            sso_sub: keycloak.tokenParsed.sub,
            student: keycloak.tokenParsed.email,
            name: keycloak.tokenParsed.given_name,
            last_name: keycloak.tokenParsed.family_name,
            student_mobile_number: keycloak.tokenParsed.phone_number ? keycloak.tokenParsed.phone_number : '',
            service_source:"VOL"
        }
    }
    axios.post(process.env.REACT_APP_API_URL, params).then((response) => {
        localStorage.setItem('student_id', response.data.data.name)
    });
}

const guardAuth = (Component) => () => {
    return keycloak && !keycloak.authenticated ? (
        keycloak.login()
    ) : (
        <>
            <NavBar/>
            <Component/>
        </>
    );
};

const AppRouter = () => {
    const {i18n} = useTranslation();
    document.body.dir = i18n.dir();

    const {initialized} = useKeycloak();
    if (!initialized) {
        return true;
    } else {
        if (keycloak.authenticated) {

            if (cookies.get('locale_changed') == "true") {
                cookies.set('language sso', cookies.get('locale'))
                cookies.set('locale_changed', "false")
            } else {
                cookies.set('language sso', keycloak.tokenParsed.language != 'undefined' ? cookies.get('language sso') : 'ar')
            }
            let languageSettings = [{
                "locale": "ar",
                'direction': 'rtl',
                'lang': 'eg',
                'country': 'العربية'
            }, {"locale": "en", 'direction': 'ltr', 'lang': 'us', 'country': 'English'}];

            let locale = cookies.get('language sso') && cookies.get('language sso') != 'undefined' ? cookies.get('language sso') : 'ar';
            cookies.set('username',keycloak.tokenParsed.name)
            cookies.set('email',keycloak.tokenParsed.email)
            cookies.set('lang', languageSettings.find(x => x.locale === locale).lang)
            cookies.set('country', languageSettings.find(x => x.locale === locale).country)
            cookies.set('direction', languageSettings.find(x => x.locale === locale).direction)
            // cookies.set('locale',cookies.get('language sso') && cookies.get('language sso') != 'undefined' ? cookies.get('language sso') : 'ar')

        }
    }
    if (!localStorage.getItem('student_id') && keycloak.authenticated) {
        getUser()
    }
    if (!keycloak.authenticated && localStorage.getItem('student_id')) {
        localStorage.removeItem('student_id')
    }
// const AppRouter = () => {
//   const {  i18n } = useTranslation();
//   document.body.dir = i18n.dir();
//
//   const { initialized } = useKeycloak();
//   if (!initialized) {
//     return true;
//   }
    return (
        // {/* <Route path="Teacher" component={Teacher} /> */}

        <Router history={history}>
            <ScrollToTop>

                <Switch>
                    <Route path="/" exact>
                        <NavBar/>
                        <Home/>
                    </Route>
                    {/*<Route path="/categoris">*/}
                    {/*    <NavBar/>*/}
                    {/*    <Categoris/>*/}
                    {/*</Route>*/}
                    <Route path="/AllCourses/:page">
                        <NavBar/>
                        <AllCourses/>
                        {/*<Categoris/>*/}
                    </Route>
                    <Route path="/bundles/">
                        <NavBar/>
                        <Bundles/>
                        {/*<Categoris/>*/}
                    </Route>
                    <Route path="/AllVolCourses/:page">
                        {guardAuth(AuthAllCourses)}
                    </Route>
                    <Route path="/add_to_cart">
                        {guardAuth(IframeCart)}
                    </Route>
                    <Route path="/search/:query/:page1/:cat">
                        <NavBar/>
                        <Search/>
                    </Route>
                    <Route path="/courses-categories/:query/:page1/:cat">
                        {guardAuth(AuthSearch)}
                    </Route>

                    <Route path="/course/:name">
                        <NavBar/>
                        <Course/>
                    </Route>

                    <Route path="/bundle/:name">
                        <NavBar/>
                        <Bundle/>
                    </Route>

                    <Route path="/empty">
                        <NavBar/>
                        <Empty/>
                    </Route>
                    <Route path="/singleteacher/:fullName/:name">
                        <NavBar/>
                        <Singleteacher/>
                    </Route>
                    <Route path="/courses">
                        <NavBar/>
                        <EmptyRedirectionRefaller/>
                    </Route>
                    <Route path="/variianceCourses/:id">
                        <NavBar/>
                        <EmptyRedirectionRefallerGeneral/>
                    </Route>
                    <Route>
                        <NavBar/>
                        <NotFound/>
                    </Route>
                </Switch>
                <Footer/>
                {/* <ExamplePage/> */}
            </ScrollToTop>
        </Router>
    );
};
export default AppRouter;
