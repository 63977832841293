import React, {Component, Fragment} from 'react';
import {withTranslation} from 'react-i18next';
import NavBar from "./NavBar";
import Thankyou from "../imgs/thankyou.png";



class EmptyRedirection extends Component {

    componentWillMount () {
        const script = document.createElement("script");

        const scriptText = document.createTextNode("!function(f,b,e,v,n,t,s)\n" +
            "{if(f.fbq)return;n=f.fbq=function(){n.callMethod?\n" +
            "        n.callMethod.apply(n,arguments):n.queue.push(arguments)};\n" +
            "    if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';\n" +
            "    n.queue=[];t=b.createElement(e);t.async=!0;\n" +
            "    t.src=v;s=b.getElementsByTagName(e)[0];\n" +
            "    s.parentNode.insertBefore(t,s)}(window, document,'script',\n" +
            "'https://connect.facebook.net/en_US/fbevents.js');\n" +
            "fbq('init', '928157661154578');\n" +
            "fbq('track', 'PageView');");

        script.appendChild(scriptText);
        document.head.appendChild(script);
        const noscript = document.createElement("noscript");

        const noscriptText = document.createTextNode("<img height=\"1\" width=\"1\" style=\"display:none\"\n" +
            "  src=\"https://www.facebook.com/tr?id=928157661154578&ev=PageView&noscript=1\"\n" +
            "/>");

        script.appendChild(noscriptText);
        document.head.appendChild(noscript);
    }


    render() {

        const {t} = this.props;

        return (
          <>
              <NavBar/>

              <div className="container">
                  <div className="row Pagethank">
                      <div className="col-lg-6">
                          <div className="px-2 text-center registerDone">
                              <p className="NotfoundTxt my-3 mt-5"> {t("done Register")}</p>
                              <p className="own_500"> {t("own 500 text")}</p>
                              <p className="continue_500"> {t("continue text")}</p>
                              <a href={process.env.REACT_APP_ALL_COURSES}>
                                  <button  className="ContinueButton" >
                                      <span className="ContinueButtonTxt">{t("go courses")} </span>
                                  </button>
                              </a>
                          </div>
                      </div>
                      <div className="col-lg-6">
                          <div className=" px-5 text-center">
                              <div className="div404">
                                  <img src={Thankyou} alt="404 not found" className="Img404"/>

                              </div>
                          </div>
                      </div>

                  </div>
              </div>


            </>


        );
    }
}

export default withTranslation()(EmptyRedirection);
