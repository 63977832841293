import React, {Component} from "react";
import '../pages/all.css';
import icon1 from "../imgs/icon9.png";
import icon2 from "../imgs/group-6.png";
import icon3 from "../imgs/group-7.png";
import keycloak from "./../keycloak";
import axios from "axios";
import {withTranslation} from "react-i18next";
import Moment from "react-moment";
import {Link} from "react-router-dom";
import {toast} from "react-toastify"
import moment from "moment";

class Singlecourseavilabilty extends Component {
    constructor(props) {
        super(props);
        this.state = {
            rounds: [],
            value: "",
            alldata: this.props.prop2,
            selectedProduct: [],
            method: "",
            isChecked: true,
            subTotal: 0,
            alertt: "",
            visible: false,
            disableFree: false,
            checkIfOneTime: false
        };
    }

    componentDidMount() {
        if (this.state.alldata.rounds.length !== 0 && this.state.alldata.rounds[0].price_after !== 0) {
            this.addToCart(this.state.alldata.rounds[0], this.state.isChecked, 0)

        }


    }

    addToCart = (res, checked, index, e) => {
        if (index === 0) {
            this.setState(
                {
                    isChecked: !this.state.isChecked,
                },
                () => {
                    let round = {
                        buyable_type: "Course Round",
                        buyable_id: res.name,
                        delivery: res.delivery_method,
                        price: res.price_after,
                        instructor: res.instructor,
                        first_name: res.first_name,
                        last_name: res.last_name,
                        discount: 0,
                    };

                    if (
                        !this.state.selectedProduct.find((obj) => obj.buyable_id === res.name)
                    ) {
                        this.setState(
                            {
                                selectedProduct: [...this.state.selectedProduct, round],
                                checkIfOneTime: true
                            },
                            () => {
                                this.sumTotal();
                            }
                        );
                    } else {
                        const items = this.state.selectedProduct.filter(
                            (item) => item.buyable_id !== res.name
                        );
                        this.setState({selectedProduct: items, checkIfOneTime: false}, () => {
                            this.sumTotal();
                        });
                    }
                }
            );
        } else {
            this.setState(
                {
                    isChecked: !this.state.isChecked,
                },
                () => {
                    let round = {
                        buyable_type: "Course Round",
                        buyable_id: res.name,
                        delivery: res.delivery_method,
                        price: res.price_after,
                        instructor: res.instructor,
                        first_name: res.first_name,
                        last_name: res.last_name,
                        discount: 0,
                    };

                    if (
                        !this.state.selectedProduct.find((obj) => obj.buyable_id === res.name)
                    ) {
                        this.setState(
                            {
                                selectedProduct: [...this.state.selectedProduct, round],
                            },
                            () => {
                                this.sumTotal();
                            }
                        );
                    } else {
                        const items = this.state.selectedProduct.filter(
                            (item) => item.buyable_id !== res.name
                        );
                        this.setState({selectedProduct: items}, () => {
                            this.sumTotal();
                        });
                    }
                }
            );
        }
    };

    notifyDone = (t) =>

        toast.warning(t("added"), {

            position: toast.POSITION.TOP_RIGHT
        })

    notifyLogin = (t) =>
        toast.error(t("pleaselogin"), {
            position: toast.POSITION.TOP_RIGHT
        })
    notifyFreeCourses = (t) =>
        toast.error(t("you are already enrolled before"), {
            position: toast.POSITION.TOP_RIGHT
        })
    notifyFreeCoursesSuccess = (t) =>
        toast.warning(t("you are enrolled successfully"), {
            position: toast.POSITION.TOP_RIGHT
        })

    sumTotal() {
        let sum = 0;
        sum = this.state.selectedProduct.reduce((prev, res) => {
            return prev + parseFloat(res.price);
        }, 0);
        this.setState({subTotal: sum}, () => {
        });
    }

    handleSubmit = () => {
        const {t} = this.props
        if (keycloak && keycloak.authenticated) {
            const newparams = {
                // url: "payment_portal/ar/add_to_cart",
                url: "api/method/variiance.payment_app.api.v2.payment_portal.add_to_cart",
                data: {
                    user_email: keycloak.tokenParsed.email,
                    cart_items: this.state.selectedProduct,
                    first_name: keycloak.tokenParsed.given_name,
                    last_name: keycloak.tokenParsed.family_name,
                    student_mobile_number: keycloak.tokenParsed.phone_number,
                    sub_total: this.state.subTotal,
                    vat: 0,
                    total: this.state.subTotal,
                },
            };

            axios.post(process.env.REACT_APP_API_URL, newparams)
                .then((response) => {
                    let res = response.status;
                    if (res === 200) {
                        this.setState({selectedProduct: [], subTotal: 0});
                        this.notifyDone(t);

                        setInterval(() => {
                            window.location.reload(false);
                        }, 600);
                    } else {
                    }
                });
        } else {
            this.notifyLogin(t)
            setTimeout(() => {
                keycloak.login()
            }, 2500);

            // this.show();
            // this.setState({ alertt: "error" });
        }
    };

    dismissAlert = () => {
        this.setState({visible: false});
    };

    show = () => {
        this.setState({visible: true});
    };
    addFreeCourses = (round_id) => {
        const {t} = this.props
        if (keycloak && keycloak.authenticated) {
            this.setState({disableFree: true})
            let lang = ""
            if (localStorage.getItem('i18nextLng') === "en" || localStorage.getItem('i18nextLng') === "en-US") {
                this.setState({lang: "en"})
                lang = "en"
                moment.locale('en-au');

            } else {
                this.setState({lang: "ar"})
                lang = "ar"
                moment.locale('ar');

            }
            const Params = {
                url: `courses/${lang}/enroll`,
                data: {
                    user_email: keycloak.tokenParsed.email,
                    round_id: round_id
                },
            };
            axios.post(process.env.REACT_APP_API_URL, Params)
                .then((response) => {
                    let res = response.data.status;
                    if (res === true) {
                        this.notifyFreeCoursesSuccess(t)
                        this.setState({enrolledSuccess: true})
                    }


                }).catch(error => {
                this.notifyFreeCourses(t)
                this.setState({disableFree: false})

            })
        } else {
            this.notifyLogin(t)
            setTimeout(() => {
                keycloak.login()
            }, 2500);
        }


    }

    render() {
        let {alertt} = this.state;
        const {t} = this.props;
        let {alldata, subTotal} = this.state;
        const Items = alldata.rounds.map((res, index) => {
            return (
                <div className="card my-3" key={res.name}>
                    <div className="card-header">
                        {res.price_after !== 0 ?
                            <div>
                                <input
                                    className="form-check-input"
                                    type="checkbox"
                                    checked={this.state.checkIfOneTime && index === 0 ? true : null}
                                    onChange={(e) => this.addToCart(res, this.state.isChecked, index, e)}
                                />
                            </div> : null}
                        <div className="date">
                            {res.delivery_method === "Online" ? (
                                <span className=" px-2">Online Round</span>
                            ) : (
                                <>
                  <span className=" px-2">
                   <Moment format="D MMMM YYYY">{res.date_from}</Moment>
                  </span>
                                    <span>-</span>{" "}
                                    <span className=" px-2">
                    {" "}
                                        <Moment format="D MMMM YYYY">{res.date_to}</Moment>{" "}
                  </span>
                                </>
                            )}
                        </div>
                        <p>{this.state.value}</p>
                    </div>
                    <div className="card-body">
                        <div className="row">
                            <div className="col-lg-8">
                                <div className="row">


                                    <div className="col-lg-6 col-md-12">
                                        <Link
                                            to={'/singleteacher/'+res.first_name+res.last_name+'/'+res.instructor}
                                            style={{textDecoration: "none"}}>

                                            <div className="mt-2 overviewfirst">
                                                <img className="overviewicon" alt="instructor" src={icon2}/>
                                                <div className="overviewtxt">
                                                    <h5 className="text1">{t("Instructor")}</h5>
                                                    <p className="text2"> {res.first_name + ' ' + res.last_name}</p>
                                                </div>
                                            </div>
                                        </Link>

                                    </div>
                                    <div className="col-lg-6  col-md-12">
                                        <div className="mt-2 overviewfirst">
                                            <img className="overviewicon" alt="learning method" src={icon3}/>
                                            <div className="overviewtxt">
                                                <h5 className="text1">{t("Learning Method")}</h5>
                                                <p className="text2"> {res.delivery_method === "Online" ? 'Recorded' : res.delivery_method}</p>
                                            </div>
                                        </div>
                                    </div>
                                    {res.address === null ? null : res.address === "" ? null : (
                                        <div className="col-lg-12 col-md-12">
                                            <div className="mt-2 overviewfirst">
                                                <img
                                                    className="overviewicon"
                                                    alt="Address"
                                                    src={icon1}
                                                />
                                                <div className="overviewtxt">
                                                    <h5 className="text1">{t("Address")}</h5>
                                                    <p className="text2"> {res.address}</p>
                                                </div>
                                            </div>
                                        </div>
                                    )}
                                </div>
                            </div>
                            <div className="col-lg-4 ">
                                <div className="allcard-txt mt-4">
                                    {res.price_after < res.price_befor ?
                                        <h5 style={{color: "#ee851d"}}>{res.price_after !== 0 ? res.price_after + " " + t('EGP') : t('Free')}{" "}
                                            <span style={{
                                                textDecoration: "line-through",
                                                fontSize: "14px",
                                                color: "#908e8e",
                                                margin: "0px 10px"
                                            }}>{res.price_befor + " " + t('EGP')}</span>
                                        </h5> :
                                        <h5 style={{color: "#ee851d"}}>{res.price_after !== 0 ? res.price_after + " " + t('EGP') : t('Free')}</h5>}
                                    {res.price_after === 0 ?
                                        <>
                                            {!this.state.enrolledSuccess ?
                                                <button
                                                    href="#"
                                                    className="btn buttonEnroll "
                                                    disabled={this.state.disableFree ? true : false}
                                                    onClick={() => this.addFreeCourses(res.name)}
                                                >
                                                    {t("Enroll now")}
                                                </button> :
                                                <a href={process.env.REACT_APP_PARTICIPANT + "/singlecourse/" + res.name}
                                                   target="_blank">
                                                    <button
                                                        href="#"
                                                        className="btn buttonEnroll "
                                                    >
                                                        {t("view Course")}
                                                    </button>
                                                </a>}
                                        </>

                                        : null}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            );
        });

        const carts = this.state.selectedProduct.map((res) => {
            return (
                <div className="card-body items" key={res.buyable_id}>
                    <div className="cartflex">
                        <div>
                            <p className="card-text">
                                <span className="spancart">{t("Round Name1")} </span>
                                {res.buyable_id}
                            </p>
                            <p className="card-text">
                                <span className="spancart">{t("Learning Method1")}</span>{" "}
                                {res.delivery === "Online" ? 'Recorded' : res.delivery}
                            </p>
                            <p className="card-text">
                                <span className="spancart">{t("Instructor1")} </span>{" "}
                                {res.first_name + " " + res.last_name}
                            </p>
                        </div>
                        <div>
                            <p className="card-text" style={{color: "#ee851d"}}>
                                {res.price !== 0 ? res.price + " " + t("EGP") : t('Free')}
                            </p>
                        </div>
                    </div>
                </div>
            );
        });

        return (
            <>
                <div className="container">
                    <div className="row">
                        <div className="">{t("Course Availability")}</div>
                        <div className="col-lg-8  col-md-8">{Items}</div>
                        <div className="col-lg-4  col-md-4">
                            <div className="card card1 my-3">
                                <h4 className="text-center mb-3">{t("Selected Rounds")} </h4>
                                {carts}
                                <ul className="list-group list-group-flush habd">
                                    <div className="list-group-item">
                                        <span className="text1">{t("Total")}</span>{" "}
                                        <span className="text2" style={{color: "#ee851d"}}>
                      {subTotal} {t("EGP")}
                    </span>
                                    </div>
                                </ul>
                                <div className="card-body text-center">

                                    <button
                                        href="#"
                                        className="btn buttonbg "
                                        disabled={this.state.selectedProduct.length === 0 ? true : false}
                                        onClick={() => this.handleSubmit()}
                                    >
                                        {t("Add to Cart")}
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        );
    }
}

export default withTranslation()(Singlecourseavilabilty);
