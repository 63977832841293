import React, {Component} from 'react';
import {withTranslation} from 'react-i18next';
import {withRouter} from "react-router-dom";
import axios from "axios";
import {Spin} from "antd";


class EmptyRedirectionRefaller extends Component {
    state = {
        isLoading: true
    }

    componentWillMount() {
        axios.get(process.env.REACT_APP_API_URL, {
            params: {
                url: "/api/resource/Influencer/" + this.props.match.params.id
            }
        })
            .then((response) => {
                window.location.href = response.data.data.link
                this.setState({
                    isLoading: false
                })
            });
    }
    render() {
        let rendered =
            this.state.isLoading ? (
                <div className="container cont">
                    <div className="row">
                        <div className="col-lg-12 col-md-12">
                            <div className="example">
                                <Spin size="large" style={{margin: "auto"}}/>
                            </div>
                        </div>
                    </div>
                </div>
            ) : null
        return <div>{rendered}</div>;
    }

}

export default withRouter(withTranslation()(EmptyRedirectionRefaller));
