import React from 'react';
import './App.css';
//pages
import AppRouter from "./Router"
import {ReactKeycloakProvider} from '@react-keycloak/web'
import keycloak from './keycloak'
import AOS from 'aos';
import 'antd/dist/antd.css';
import {ToastContainer} from "react-toastify"
import "react-toastify/dist/ReactToastify.css"
import Cookies from "universal-cookie";
import 'animate.css';
import {Helmet} from "react-helmet"

const cookies = new Cookies();
let dir = cookies.get("direction")
    ? cookies.get("direction")
    : "rtl";
let siteLanguage = ''
if (dir === "ltr") {
    import('./pages/all.css')
     siteLanguage = 'en'
} else {
    import('./pages/all.rtl.css')
     siteLanguage = 'ar'
}
AOS.init();
// import Teacher from './pages/Teacher.js ';

/**
 * @description Main compoenent for our app, handles routing & acts as the entry point.
 * @extends {React.Component}
 */
export default class App extends React.Component {


    /**
     * @description Responsible for rendering the component, hides & shows the dialogue based on `state.dialogShown`
     * @returns {JSX}
     */
    render() {
        return (
            <>
                <Helmet htmlAttributes={{
                    lang: siteLanguage,
                }}>
                <title lang={siteLanguage}> </title>
                </ Helmet>
                <ReactKeycloakProvider authClient={keycloak}>
                    <div className="App">
                        <ToastContainer/>
                        <AppRouter/>
                    </div>

                </ReactKeycloakProvider>
            </>
        )
    }
}

