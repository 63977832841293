import i18n from "i18next";
import {initReactI18next} from "react-i18next";
import LanguageDetector from 'i18next-browser-languagedetector';
import translationsEN from './en/en.json'
import translationsHE from './ar/ar.json'
import keycloak from "../keycloak";

import Cookies from 'universal-cookie';


const cookies = new Cookies();

let languageSettings = [{"locale": "ar", 'direction': 'rtl','lang':'eg','country':'العربية'}, {"locale": "en", 'direction': 'ltr','lang':'us','country':'English'}];

// let locale = cookies.get('language sso') && cookies.get('language sso') != 'undefined' ? cookies.get('language sso') : 'ar';
// cookies.set('locale', locale)
let locale = cookies.get('language sso') && cookies.get('language sso') != 'undefined' ? cookies.get('language sso') : 'ar';
cookies.set('lang', languageSettings.find(x=>x.locale === locale).lang)
cookies.set('country', languageSettings.find(x=>x.locale === locale).country)

const resources = {
    en: {
        translation: translationsEN
    },
    ar: {
        translation: translationsHE
    }
};

i18n
    .use(initReactI18next) // passes i18n down to react-i18next
    .use(LanguageDetector)
    .init({
        resources,
        lng: cookies.get('language sso') && cookies.get('language sso') != 'undefined' ? cookies.get('language sso') : 'ar' ,// this is the default language. Try to put this

        fallbackLng: 'en', // default language
        keySeparator: false, // we do not use keys in form messages.welcome
        interpolation: {
            escapeValue: false // react already safes from xss
        }
    });

export default i18n;