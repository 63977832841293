import React, {Component} from 'react';
import img from '../imgs/group-20.png'
// import '../pages/all.css'
import {Link} from 'react-router-dom'
import {withTranslation} from 'react-i18next';

class Empty extends Component {
    state = {}

    render() {
        const {t} = this.props;

        return (
            <div className="container cont">

                <div className="row">
                    <div className="col-md-12 text-center mt-5">
                        <img className="picnot" src={img}  alt="not found courses"/>
                        <h1 className="not_found mt-4">{t('Courses not found?')}</h1>
                        <h6 className="explore_notFound mb-4">{t('books_courses')}<br/>{t('Courses and book stays')}
                        </h6>
                        <Link to="/AllCourses/1" className="btn_notFound mt-5">{t('Explore new Courses')}</Link>

                    </div>


                </div>

            </div>);
    }
}

export default withTranslation()(Empty);