import React, {Component} from "react";
import axios from "axios";
// import keycloak from "../../keycloak";
import i18n from "../i18n/i18n";
import {Translation, withTranslation} from "react-i18next";
import img from "../imgs/chat (1).svg"
import headimg from "../imgs/form.png"
import X from "../imgs/iconX.svg"

let errors = {};
let formIsValid = true;

class Popup extends Component {
    state = {
        fullName: "",
        message: "",
        phone: "",
        visible: false,
        alertt: "",
        disableButton:false,
        finishFeedback:true,
        errors: {},
    };
componentDidMount() {

}

    handleChange = (event) => {
        this.setState({[event.target.name]: event.target.value})

        if (!this.state.fullName) {
            formIsValid = false;

            errors.fullName = (
                <Translation i18n={i18n}>
                    {
                        (t, {i18n}) => <p>{t("name cannot be empty")}</p> // will be looked up from namespace ns1
                    }
                </Translation>
            );
        }else if (this.state.fullName.length < 3) {
            formIsValid = false;

            errors.fullName = (
                <Translation i18n={i18n}>
                    {
                        (t, {i18n}) => <p>{t("Cannot less than three char")}</p> // will be looked up from namespace ns1
                    }
                </Translation>
            );
        }else{
            errors.fullName =("")
        }
        if (!this.state.message) {
            formIsValid = false;

            errors.message = (
                <Translation i18n={i18n}>
                    {
                        (t, {i18n}) => <p>{t("message cannot be empty")}</p> // will be looked up from namespace ns1
                    }
                </Translation>
            );
        }else if (this.state.message.length < 10) {
            formIsValid = false;

            errors.message = (
                <Translation i18n={i18n}>
                    {
                        (t, {i18n}) => <p>{t("Cannot less than ten char")}</p> // will be looked up from namespace ns1
                    }
                </Translation>
            );
        }else {
            errors.message = ('')

        }
        this.setState({errors: errors});

    };

    dismissAlert = () => {
        this.setState({visible: false});
    };



    show = () => {
        this.setState({visible: true});
    };

    handleValidation = () => {
        let {fullName, message} = this.state;
        let errors = {};
        let formIsValid = true;

        if (!fullName) {
            formIsValid = false;

            errors.fullName = (
                <Translation i18n={i18n}>
                    {
                        (t, {i18n}) => <p>{t("name cannot be empty")}</p> // will be looked up from namespace ns1
                    }
                </Translation>
            );
        }else if (fullName.length < 3) {
            formIsValid = false;

            errors.fullName = (
                <Translation i18n={i18n}>
                    {
                        (t, {i18n}) => <p>{t("Cannot less than three char")}</p> // will be looked up from namespace ns1
                    }
                </Translation>
            );
        }
        if (!message) {
            formIsValid = false;

            errors.message = (
                <Translation i18n={i18n}>
                    {
                        (t, {i18n}) => <p>{t("message cannot be empty")}</p> // will be looked up from namespace ns1
                    }
                </Translation>
            );
        }else if (message.length < 10) {
            formIsValid = false;

            errors.message = (
                <Translation i18n={i18n}>
                    {
                        (t, {i18n}) => <p>{t("Cannot less than ten char")}</p> // will be looked up from namespace ns1
                    }
                </Translation>
            );
        }


        this.setState({errors: errors});
        return formIsValid;
    };

    handleSubmit = () => {
        if (this.handleValidation() === false) {
        } else {
            this.setState({disableButton:true})
            const newparams = {
                url: "api/resource/VOL_feedback",
                data: {
                    user_name: this.state.fullName,
                    mobile: this.state.phone,
                    message: this.state.message,
                },
            };
            axios.post(process.env.REACT_APP_API_URL, newparams)
                .then((response) => {
                    let res = response.status

                    if (res === 200) {
                        this.setState({
                            fullName: "",
                            phone: "",
                            message: "",
                            disableButton:false,
                            finishFeedback:false
                        });

                    } else {

                    }
                });
        }
    };
    appearForm =()=>{
        setTimeout(() => {
                    this.setState({finishFeedback: true});
                }, 1000);

    }

    render() {
        const {t} = this.props;
        let {alertt} = this.state;
        let {errors} = this.state;
        return (
            <>
                <div
                    className="modalsec "
                    data-bs-toggle="modal"
                    data-bs-target="#exampleModal"
                >
                    <div className="my-2">
                    <img className="m-auto d-block"
                        src={img}
                        width="40px"
                         alt="message us"
                        height="40px"

                    />
                    <p className="modaltxt">{t("Message Us")}</p>
                    </div>
                </div>
                <div
                    className="modal fade"
                    id="exampleModal"
                    tabIndex="-1"
                    aria-labelledby="exampleModalLabel"
                    aria-hidden="true"
                    // style="z-index: 9999999"
                    style={{zIndex: '9999999'}}
                >


                    <div className="modal-dialog modal-dialog-centered modal-dialog-scrollable">
                        <div className="modal-content">
                            <div className="row modalheadd">
                                <div className="col-sm-12 col-xs-12">
                                    <img
                                        className="imgmodal"
                                        src={headimg}
                                        width="70%"
                                        alt="feedback"
                                        height="70%"
                                    />
                                    <p className="formtxt text-center mb-2">{t("Let us know your feedback")}</p>
                                </div>
                                <button
                                    type="button"
                                    className="btn-close"
                                    data-bs-dismiss="modal"
                                    aria-label="Close"
                                    onClick={()=>this.appearForm()}
                                >
                                    <img src={X} alt="close"/>
                                </button>

                            </div>

                            <div className="modal-body">
                                {this.state.finishFeedback ?
                                <form onSubmit={(e) => e.preventDefault()}>

                                    <div className="group">
                                        <input
                                            className="inputMaterial"
                                            type="text"
                                            name="fullName"
                                            value={this.state.fullName}
                                            // required
                                            // minLength="3"
                                            onSelectCapture={this.handleChange}
                                            onChange={this.handleChange}
                                        />
                                        <span className="highlight"></span>
                                        <span className="bar"></span>
                                        <label className="label"> {t("Name")}</label>
                                    </div>
                                    <span className="err">{errors.fullName}</span>

                                    <div className="group">
                                        <input
                                            className="inputMaterial"
                                            type="number"
                                            name="phone"
                                            value={this.state.phone}
                                            // required
                                            onSelectCapture={this.handleChange}
                                            onChange={this.handleChange}
                                            pattern="^(01|00201|\+201|201)[0|1|2|5]{1}\d{8}"
                                        />
                                        <span className="highlight"></span>
                                        <span className="bar"></span>
                                        <label className="label">{t("Mobile")}</label>
                                    </div>
                                    <div className="group1">
                                        <textarea
                                            className="inputMaterial "
                                            type="text"
                                            name="message"
                                            value={this.state.message}
                                            onSelectCapture={this.handleChange}
                                            onChange={this.handleChange}
                                        ></textarea>
                                        <span className="highlight"></span>
                                        <span className="bar"></span>
                                        <label className="label">{t("Message")}</label>
                                    </div>
                                    <span className="err">{errors.message}</span>

                                    <div className="text-center">
                                        <button
                                            className="btn btn-primary btnformsign"
                                            type="submit"
                                            disabled={this.state.disableButton}
                                            onClick={this.handleSubmit}
                                        >
                                            {t("Submit")}
                                        </button>
                                    </div>
                                </form>:<div>
                                        <p className="formFinish text-center mb-2">{t("Thank you for your feedback")}</p>

                                    </div>
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </>
        );
    }
}

export default withTranslation()(Popup);
