import React, {Component} from 'react';
import arrow from '../imgs/arrow-right-3.svg';
import {Link, withRouter} from 'react-router-dom';
import axios from 'axios'
import {withTranslation} from 'react-i18next';
import {Spin} from "antd";
import MetaTags from "react-meta-tags";
import star from "../imgs/star.png";
import moment from "moment";
import Empty from "../components/empty";
import {Helmet} from "react-helmet";
import NavBar from "./NavBar";

class AllCourses extends Component {

//
// componentDidUpdate(prevProps, prevState, snapshot) {
//    localStorage.setItem('i18nextLng',this.state.lang)
//
    state = {
        data: [],
        lang: "",
        isLoading: true,
        metatittle: '',
        searchText: "",
        searchResults: [],
        results: [],
        pag: 0,
        total: null,
        per_page: 5,
        current_page: 1,
        parent: [],
        parentCat: [],
        newQuery: "",
        removeClick: true,
        metaItemsName: [],
        metaItemsProperty: [],
        url: ""


    }

// }
    async handleSubmit() {
        let lang = ""
        // this.setState({lang:"ar"})
        if (localStorage.getItem('i18nextLng') === "en" || localStorage.getItem('i18nextLng') === "en-US") {
            lang = "en"
        } else {
            lang = "ar"
        }
        await axios.get(process.env.REACT_APP_API_URL, {
            params: {
                // url: 'courses/' + lang + '/categories'
                url: `api/method/variiance.vol.api.v2.courses.categories?lang=${lang}`
            }

        })
            .then(response => {
                let data = response.data.data
                this.setState({data})

            })

    }
    componentDidUpdate(prevProps) {
        if(prevProps.match.params.page !== this.props.match.params.page){
            this.setState({removeClick:false},()=>{
                this.handleSearch(this.props.match.params.page)
            })
        }
    }

    handleSearch = (e) => {

        let lang = "";
        if (
            localStorage.getItem("i18nextLng") === "en" ||
            localStorage.getItem("i18nextLng") === "en-US"
        ) {
            this.setState({lang: "en"});
            lang = "en";
        } else {
            this.setState({lang: "ar"});
            lang = "ar";
        }
        let limit = 0
        let page = e || this.props.match.params.page || 1 || this.props.match.params.name
        let href = "/AllCourses/" + page
        if (this.state.removeClick === true) {
            this.props.history.push({
                pathname: href,
            });
        }


        axios.get(process.env.REACT_APP_API_URL, {
            params: {
                // url: `/courses/${lang}/index?limit=5&page=${page}`
                url: `/api/method/variiance.vol.api.v2.courses.index?limit=5&page=${page}&lang=${lang}`

            }
        })
            .then(response => {

                this.setState({
                    results: response.data.data.records,
                    total: response.data.data.meta.total_count,
                    current_page: page,
                    pag: response.data.data.meta.pagination,
                    isLoading: false,
                    removeClick: true
                })
                this.topscroll()
            })
    }


    componentDidMount() {

        this.handleSubmit()

        this.setState({url: this.props.match.url})

        let lang = "";
        if (
            localStorage.getItem("i18nextLng") === "en" ||
            localStorage.getItem("i18nextLng") === "en-US"
        ) {
            this.setState({lang: "en"});
            lang = "en";
        } else {
            this.setState({lang: "ar"});
            lang = "ar";
        }
        this.setState({removeClick: false}, () => {
            this.handleSearch(this.props.match.params.page);
        })

        axios.get(process.env.REACT_APP_API_URL, {
            params: {
                url: "api/resource/VTSMetaTags/Categories_VOL_" + lang
            }
        }).then((response) => {
            let metaItemsName = response.data.data.meta_tags.filter((res) => {
                if (res.type === "name") {
                    return res
                }
            })
            let metaItemsProperty = response.data.data.meta_tags.filter((res) => {
                if (res.type === "property") {
                    return res
                }
            })
            this.setState({metaItemsName, metaItemsProperty})
            let metatittle = response.data.data.page_title;
            this.setState({metatittle: metatittle})

        });


    }

    topscroll = (e) => {
        window.scrollTo(0, 0)
    };


    render() {
        const {t} = this.props;
        let renderPageNumbers;
        const pageNumbers = [];
        if (this.state.total !== null) {
            for (let i = 1; i <= Math.ceil(this.state.total / this.state.per_page); i++) {
                pageNumbers.push(i);
            }
            renderPageNumbers = pageNumbers.map(number => {
                let classes = this.state.current_page == number ? "page-item page-item-active " : 'page-item ';

                return (
                    <li className={classes} style={{cursor: "pointer"}} key={number}
                        onClick={() => this.handleSearch(number)}>
                        {number}
                    </li>
                );
            });
        }

        let {data} = this.state
        let singleMetaName = this.state.metaItemsName.map(res => {
            return <meta key={res.key} name={res.key} content={res.value}/>
        })
        let singleMetaProperty = this.state.metaItemsProperty.map(res => {
            return <meta key={res.key} property={res.key} content={res.value}/>
        })
        const Items = data.map(res => {
            return (

                <div className="col-lg-2 col-md-4 col-xs-6 my-2">
                    <Link style={{textDecoration: "none", color: "#908e8e"}}
                          to={"/search/" + encodeURIComponent(res.parent_category) + "/1" + "/p"}>

                        <div className="TabsCategory" data-aos="flip-left" data-aos-duration="3000" key={res.name1}>

                            <span>  {res.name1}</span>


                        </div>
                    </Link>
                </div>


            )

        })
        let {results} = this.state
        let parent = ""
        let single = ""
        let singleCourse = results.map(res => {
            let method = []
            parent = res.parent_category_name
            single = res.category_name
            if (res.delivery_methods) {
                method = res.delivery_methods.map(res_method => {
                    return <span className="spann" key={Math.random()}>{res_method}</span>
                });
            }
            return (
                <div className="row " key={res.name}>
                    <div className="col-lg-12 my-3 mobcard " data-aos="fade-up" data-aos-duration="3000">
                        <div className="row">
                            <div className="col-lg-7 ">
                                <div className="row">
                                    <div className="col-lg-5 m-auto">
                                        <Link className="removelink1"
                                              to={"/course/" + encodeURIComponent(res.name)}> <img className="courseimg"
                                                                                                   alt={res.image_alt}
                                                                                                   style={{
                                                                                                       width: '210px',
                                                                                                       height: '150px'
                                                                                                   }}
                                                                                                   src={res.image}/>
                                        </Link>
                                    </div>
                                    <div className=" col-lg-7 coursecontent ">
                                        <h5><Link className="removelink1"
                                                  to={"/course/" + encodeURIComponent(res.name)}>{res.title}</Link>
                                        </h5>
                                        <p><Link className="removelink"
                                                 to={"/search/" + encodeURIComponent(res.category) + "/1" + "/c"}
                                        >{res.category_name}</Link>
                                        </p>
                                        {localStorage.getItem('i18nextLng') === "en" || localStorage.getItem('i18nextLng') === "en-US" ?
                                            <div className="mb-1"><img src={star} alt="star_logo"/> <span
                                                className="spannn">{moment
                                                .duration(res.course_houre, "minutes")
                                                .format("h [hr]  m [min]", {trim: "both"})}</span>
                                            </div> : <div className="mb-1"><img src={star} alt="star_logo"/> <span
                                                className="spannn">{moment
                                                .duration(res.course_houre, "minutes")
                                                .format("h [ساعة]  m [دقيقة]", {trim: "both"})}</span>
                                            </div>

                                        }

                                        <div className="mb-1"><span>{method}</span></div>
                                    </div>

                                </div>
                            </div>
                            <div className=" courseprice col-lg-5 my-auto">
                                {res.displayed_price_after < res.displayed_price_before ?
                                    <h5>{res.displayed_price_after !== 0? res.displayed_price_after +" "+ t('EGP') : t('Free')}{" "}
                                        <span style={{
                                            textDecoration: "line-through",
                                            fontSize: "14px",
                                            color: "#908e8e",
                                            margin:"0px 10px"
                                        }}>{res.displayed_price_before +" "+ t('EGP')}</span>
                                    </h5>: <h5>{res.displayed_price_after !== 0? res.displayed_price_after +" "+ t('EGP') : t('Free')}</h5>}
                            </div>
                        </div>


                    </div>
                </div>

            )
            // this.setState({parent:res.parent_category})
        })


        let rendered = this.state.isLoading ?
            (
                <div className="container cont">
                    <div className="row">
                        <div className="col-lg-12 col-md-12">

                            <div className="example">
                                <Spin size="large" style={{margin: "auto"}}/>
                            </div>
                        </div>
                    </div>
                </div>

            ) : (

                <>
                    {this.state.results.length > 0 ? (


                        <div className="container cont">
                            <div className="row">
                                <div className="mt-5 srchhead col-lg-12">
                                    <h1 className="mt-5">{t('Explore All')} <span
                                        style={{color: "#ee851d"}}>{t('Courses')}</span></h1>
                                    <hr/>
                                </div>
                            </div>

                            <div className="CoursesTabs row ">
                                <div className="col-lg-2 col-md-4 col-xs-6 my-2">
                                    <div className=" activeTabs" data-aos="flip-left" data-aos-duration="3000">
                      <span style={{textDecoration: "none", color: "#f2871e", cursor: "pointer"}}>
                                {t('All Courses')}
                           </span>
                                    </div>
                                </div>

                                {Items}
                            </div>
                            <div className="row">

                                <div className="container">

                                    <div className="row mt-3">
                                        <div className="col-lg-9">

                                            {singleCourse}
                                        </div>


                                    </div>
                                    <div className="pb-5">
                                        <nav aria-label="Page navigation example ">
                                            <ul className="pagination justify-content-left">
                                                {renderPageNumbers}

                                                <li
                                                    className={this.state.current_page == this.state.pag ? "nonee" : "page-item pag"}
                                                    style={{
                                                        color: "#908e8e",
                                                        textDecoration: "none",
                                                        fontWeight: "600",
                                                        cursor: "pointer"
                                                    }}
                                                    onClick={() => this.handleSearch(parseInt(this.props.match.params.page) + 1)}>


                                                    {t('Next')}
                                                </li>
                                            </ul>
                                        </nav>
                                    </div>
                                </div>


                            </div>

                        </div>
                    ) : (
                        <Empty/>
                    )}
                </>

            );
        return (<div>
            <NavBar/>
            {rendered}
            <Helmet>
                <link href={"https://vol.variiance.com" + this.state.url} rel="canonical"/>
            </Helmet>
            <MetaTags>
                <title>{this.state.metatittle}</title>
                {singleMetaName}
                {singleMetaProperty}
            </MetaTags>
        </div>)
    }
}

export default withRouter(withTranslation()(AllCourses));
