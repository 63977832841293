import React, {Component} from 'react';
import arrow from '../imgs/arrow-right-3.svg';
import {Link} from 'react-router-dom';
import axios from 'axios'
import {withTranslation} from 'react-i18next';
import {Spin} from "antd";
import MetaTags from "react-meta-tags";

class Categoris extends Component {

//
// componentDidUpdate(prevProps, prevState, snapshot) {
//    localStorage.setItem('i18nextLng',this.state.lang)
//
    state = {
        data: [],
        lang: "",
        isLoading: true,
        metaTags: [],
        metatittle:''

    }

// }
    async handleSubmit() {
        let lang = ""
        // this.setState({lang:"ar"})
        if (localStorage.getItem('i18nextLng') === "en" || localStorage.getItem('i18nextLng') === "en-US") {
            lang = "en"
        } else {
            lang = "ar"
        }
        await axios.get(process.env.REACT_APP_API_URL, {
            params: {
                // url: 'courses/' + lang + '/categories'
                url: `api/method/variiance.vol.api.v2.courses.categories?lang=${lang}
`
            }

        })
          .then(response => {
              let data = response.data.data
              this.setState({data})
              this.setState({
                  isLoading: false,


              })


            })

    }

    componentDidMount() {
        this.handleSubmit()

        let lang = "";
        if (
          localStorage.getItem("i18nextLng") === "en" ||
          localStorage.getItem("i18nextLng") === "en-US"
        ) {
            this.setState({lang: "en"});
            lang = "en";
        } else {
            this.setState({lang: "ar"});
            lang = "ar";
        }
        axios.get(process.env.REACT_APP_API_URL, {
            params: {
                url: "api/resource/VTSMetaTags/Categories_VOL_" + lang
            }
        }).then((response) => {
            let metaTags = response.data.data.meta_tags;
            let metatittle = response.data.data.page_title;
            this.setState({metaTags: metaTags})
            this.setState({metatittle: metatittle})

        });


    }
//     componentDidUpdate(){
//         window.onpopstate = e => {
// this.props.history.goBack()
//         }
//     }


    render() {
        const {t} = this.props;

        let {data} = this.state
        const metaItems = this.state.metaTags.map((res) => {
            return(

                <meta key={res.key} name={res.key} content={res.value}/>

            )
        })
        const Items = data.map(res => {
            return (

                <div className="mt-5 col-lg-4" data-aos="flip-left" data-aos-duration="3000" key={res.name1}>
                    <Link style={{textDecoration: "none"}}
                          to={"/search/" + encodeURIComponent(res.parent_category) + "/1" + "/p"}>
                        <div className="card catgcart">
                            <img style={{width: '69px', height: '69px'}}
                                 src={res.hero_image} tintColor="blue"
                                 className="imageForHover" alt={res.hero_image_alt}/>
                            <div className="card-body">
                                <h5>{res.name1}</h5>
                                <p className="text2"> {res.courses_count === 0 ? t("No courses yet") : res.courses_count >= 10 ? t("More than 10 courses") : res.courses_count < 10 ? t("Less than 10 courses") : null} </p>
                            </div>
                            <img className="arrow" alt="arrow" src={arrow}/>
                        </div>
                    </Link>
                </div>

            )

        })


        let rendered = this.state.isLoading ?
            (
                <div className="container cont">
                    <div className="row">
                        <div className="col-lg-12 col-md-12">

                            <div className="example">
                                <Spin size="large" style={{margin: "auto"}}/>
                            </div>
                        </div>
                    </div>
                </div>

            ) : (

                <>
                    <div className="container cont">

                        <div className="row">

                            <div className="mt-5 catghead col-lg-12">
                                <h2>{t('Get inspiration for your next Learning Experience')}</h2>
                            </div>

                            {Items}

                        </div>

                    </div>
                </>

            );
        return (<div>{rendered}
            <MetaTags>
                <title>{this.state.metatittle}</title>
                {metaItems}
            </MetaTags>
        </div>)
    }
}

export default withTranslation()(Categoris);
