import React, {Component} from 'react';
import {Modal, ModalBody, ModalFooter, ModalHeader,} from "reactstrap"
import ReactPlayer from "react-player";
import {withTranslation} from "react-i18next";
import avtar from "../imgs/avatar.png";
import {GrCart} from "react-icons/all";
import axios from "axios";
import {Spin} from "antd";
import keycloak from "../keycloak";
import moment from "moment";
import {toast} from "react-toastify";
import {Link} from "react-router-dom";

class BriefCoursePopup extends Component {
    state = {
        isLoading: true,
        enrolledSuccess:false
    }

    async componentDidMount() {
        const {course_id, lang} = this.props
        const userId = localStorage.getItem('student_id')

        await axios.get(process.env.REACT_APP_API_URL, {
            params: {
                // url: `courses/${lang}/show/${course_id}/${userId}`
                url: `api/method/variiance.vol.api.v2.courses.show?lang=${lang}&course_id=${course_id}&user=${userId}`
            }
        }).then((response) => {
            let course = response.data.data.course;
            let round = response.data.data.rounds[0];
            let videos_count = response.data.data.videos_count;
            if (response.data.data.rounds[0]?.is_enrolled == 1) {
                this.setState({enrolledSuccess: true})
            } else {
                this.setState({enrolledSuccess: false})
            }
            this.setState({course, round, videos_count, isLoading: false})
        })

        if (this.props.appearPopup) {
            document.body.style.overflow = 'hidden';
        }
    }

    notifyFreeCourses = (t) =>
        toast.error(t("you are already enrolled before"), {
            position: toast.POSITION.TOP_RIGHT
        })
    notifyFreeCoursesSuccess = (t) =>
        toast.warning(t("you are enrolled successfully"), {
            position: toast.POSITION.TOP_RIGHT
        })
    notifyLogin = (t) =>
        toast.error(t("pleaselogin"), {
            position: toast.POSITION.TOP_RIGHT
        })


    addFreeCourses = (round_id) => {
        const {t} = this.props
        if (keycloak && keycloak.authenticated) {
            this.setState({disableFree: true})
            let lang = ""
            if (localStorage.getItem('i18nextLng') === "en" || localStorage.getItem('i18nextLng') === "en-US") {
                this.setState({lang: "en"})
                lang = "en"
                moment.locale('en-au');

            } else {
                this.setState({lang: "ar"})
                lang = "ar"
                moment.locale('ar');

            }
            const Params = {
                url: `courses/${lang}/enroll`,
                data: {
                    user_email: keycloak.tokenParsed.email,
                    round_id: round_id
                },
            };
            axios.post(process.env.REACT_APP_API_URL, Params)
                .then((response) => {
                    let res = response.data.status;
                    if (res === true) {
                        this.notifyFreeCoursesSuccess(t)
                        this.setState({enrolledSuccess: true})
                    }
                }).catch(error => {
                this.notifyFreeCourses(t)
                this.setState({disableFree: false})

            })
        } else {
            this.notifyLogin(t)
            setTimeout(() => {
                keycloak.login()
            }, 2500);
        }
    }

    componentWillUnmount() {
        document.body.style.overflow = 'unset';
    }

    render() {
        let {course, round, videos_count} = this.state

        const {appearPopup, disAppearPopup, addToCart, buyNow, showButtons, t} = this.props

        let rendered = (this.state.isLoading) ? (
            <Modal isOpen={this.state.isLoading} toggle={disAppearPopup} className='my-modal'>
                <ModalBody className='my-modal-body'>
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12 col-md-12">

                                <div className="example">
                                    <Spin size="large" style={{margin: "auto"}}/>
                                </div>
                            </div>
                        </div>
                    </div>
                </ModalBody>
            </Modal>) : (<Modal isOpen={appearPopup} toggle={disAppearPopup} className='my-modal'>
            <ModalHeader className='my-modal-header' toggle={disAppearPopup}>
                <div className='my-modal-video'>
                    {!course.intro_video ? <img
                            width="100%"
                            height="100%"
                            alt="Image course"
                            src={course.image}
                        /> :
                        <ReactPlayer url={course.intro_video} width="100%" height="100%" controls={true}
                                     className='react-player' playsinline={true}/>
                        // className='react-player' light={course.image} playsinline={true} playing={true}/>
                    }
                </div>
            </ModalHeader>
            <ModalBody className='my-modal-body'>
                <div className='my-modal-body-first-section'>
                    <div className="row justify-content-between">
                        <div className="col-md-6">
                            <div className='my-modal-body-first-section-container'>
                                <h3 className='my-modal-body-first-section-container-title'>{course.title}</h3>
                                <p className='my-modal-body-first-section-container-hint'
                                   dangerouslySetInnerHTML={{__html: course.who_should_attend}}/>
                            </div>
                            <div className='my-modal-body-second-section-desc-container'>
                                <div className='my-modal-body-second-section-desc-container-description'>
                                    <p dangerouslySetInnerHTML={{__html: course.description}}/>
                                    <div className='my-modal-body-second-section-img-container'>
                                        <img className='my-modal-body-second-section-img-container-img'
                                             src={round.image ? round.image : avtar}
                                             alt='instructor-img'/>
                                        <div className='my-modal-body-second-section-img-container-info'>
                                            <p className='my-modal-body-second-section-img-container-info-name'>
                                                <Link to={`/singleteacher/${round.first_name}-${round.last_name}/${round.instructor}`}>
                                                    {round.first_name} {round.last_name}
                                                </Link>
                                            </p>
                                            <p className='my-modal-body-second-section-img-container-info-date'>
                                                {t('joining_date')}
                                                {round.joining_date.split(' ')[0]}
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-6">
                            {showButtons &&
                                <div className='my-modal-body-first-section-pricing'>
                                    <div className='my-modal-body-first-section-pricing-button-container'>
                            <span className='button-container-buy' >
                                {/*<img*/}
                                {/*    className="button-container-buy-icon"*/}
                                {/*    alt="creditCardIcon"*/}
                                {/*    src={creditCardIcon}*/}
                                {/*/>*/}
                                {round ? round.price_after === 0 ?
                                    <>
                                    {!this.state.enrolledSuccess ?
                                <button
                                    className="btn button-container-buy-btn w-100"
                                    disabled={this.state.disableFree ? true : false}
                                    onClick={() => this.addFreeCourses(round.name)}
                                >
                                    {t("Enroll now")}

                                </button>:
                                        <a className="button-container-buy-btn w-100" href={process.env.REACT_APP_PARTICIPANT + "/singlecourse/" +round.name}
                                           target="_blank">
                                        <button
                                            className="btn marginTopFive button-container-buy-btn w-100"

                                        >
                                            {t("view Course")}

                                        </button></a> }</>
                                    : !this.state.enrolledSuccess ?
                                        <button
                                            className="btn button-container-buy-btn w-100"
                                            onClick={() => buyNow(round)}
                                            disabled={this.props.buyRound}
                                        >
                                            {t("buy_now")}

                                        </button>:
                                        <a className="button-container-buy-btn w-100" href={process.env.REACT_APP_PARTICIPANT + "/singlecourse/" +round.name}
                                           target="_blank">
                                            <button
                                                className="btn marginTopFive button-container-buy-btn w-100"

                                            >
                                                {t("view Course")}

                                            </button></a> :null}
                            </span>
                                    </div>
                                    <p className='my-modal-body-first-section-pricing-price'>
                                        {round.price_after !== 0 ? t('price_is') + round.price_after + t('EGP') :t('Free') }
                                        {round.price_befor > round.price_after &&
                                            <span style={{
                                                'padding': '5px',
                                                'color': 'rgb(144, 142, 142)',
                                                'textDecoration': 'line-through'
                                            }}>{round.price_befor} {t('EGP')}</span>
                                        }
                                    </p>
                                </div>
                            }
                            <div className='my-modal-body-second-section-course-info-container'>
                                <div className='my-modal-body-second-section-course-info-container-time'>
                                    <p className='my-modal-body-second-section-course-info-container-time-text'>
                                        {t('Duration')}</p>
                                    <p className='my-modal-body-second-section-course-info-container-time-info'>
                                        {course.course_houre}</p>
                                </div>
                                <div className='my-modal-body-second-section-course-info-container-method'>
                                    <p className='my-modal-body-second-section-course-info-container-method-text'>
                                        {t('Learning Method')}
                                    </p>
                                    <p className='my-modal-body-second-section-course-info-container-method-info'>
                                        {round.delivery_method}</p>
                                </div>
                                <div className='my-modal-body-second-section-course-info-container-courses'>
                                    <p className='my-modal-body-second-section-course-info-container-courses-text'>
                                        {t("videosCount")}
                                    </p>
                                    <p className='my-modal-body-second-section-course-info-container-courses-info'>{videos_count}
                                        {t("video")}
                                    </p>
                                </div>
                                {/*<div className='my-modal-body-second-section-course-info-container-certification'>*/}
                                {/*    <p*/}
                                {/*        className='my-modal-body-second-section-course-info-container-certification-text'>*/}
                                {/*        {t("Certificate")}*/}
                                {/*    </p>*/}
                                {/*    <p className='my-modal-body-second-section-course-info-container-certification-info'>*/}
                                {/*        {t("Certificate of completion")}</p>*/}
                                {/*</div>*/}
                                {/*<div className='my-modal-body-second-section-course-info-container-ignore-method'>*/}
                                {/*    <p className='my-modal-body-second-section-course-info-container-ignore-method-text'>*/}
                                {/*        {t('Cancellation policy')}*/}
                                {/*    </p>*/}
                                {/*    <p className='my-modal-body-second-section-course-info-container-ignore-method-info'>*/}
                                {/*        {t('Flexible cancellation details')}</p>*/}
                                {/*</div>*/}
                                <div className='my-modal-body-second-section-course-info-container-field'>
                                    <p className='my-modal-body-second-section-course-info-container-field-text'>
                                        {t('course area')}</p>
                                    <p className='my-modal-body-second-section-course-info-container-field-info'>
                                        <a href={`${process.env.REACT_APP_COURSES}/search/${course.category}/1/c`}
                                           target="_blank">
                                            {course.category_name}
                                        </a>
                                    </p>
                                </div>
                                <div className='my-modal-body-second-section-course-info-container-more'>
                                    <a href={`${process.env.REACT_APP_COURSES}/course/${course.course}`}
                                       target="_blank">
                                        {t("More details about the course")}
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </ModalBody>
            <ModalFooter>
            </ModalFooter>
        </Modal>)

        return (
            <>
                {rendered}
            </>);
    }
}

export default withTranslation()(BriefCoursePopup);
