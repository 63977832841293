import * as types from '../actions/actionTypes';

const cartReducer = (state = 0, action) => {
    let doesItemExist;
    switch (action.type) {
        case types.ADD_TO_CART:

            if (action.payload.addOne === 'No')
                return action.payload.cartCount;

            return state + action.payload.cartCount;


        default:
            return state;
    }
}

export default cartReducer;
