import React from 'react';
import ReactDOM from 'react-dom';
import './index.scss';
import App from './App';
import reportWebVitals from './reportWebVitals';
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.bundle.min";
import {Provider} from "react-redux";
import {createStore} from "redux";
import rootReducer from "./redux/reducers/rootReducer";

const store = createStore(rootReducer, {});

ReactDOM.render(
    <Provider store={store}>
    <App/>
    </Provider>
    ,
    document.getElementById('root')
);
// document.getElementsByTagName('html')[0].getAttribute("dir", localStorage.getItem('direction'));

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
