import React, {Component} from "react";
import homeImg from "../imgs/home-repeat.png";
import homeImgAR from "../imgs/vol-ar-repeat.png";
import {Spin} from "antd";
import axios from "axios";
import {withTranslation} from "react-i18next";
import MetaTags from "react-meta-tags";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import {NavHashLink} from "react-router-hash-link";
import Slider from "../components/slider";
import BundleSlider from "../components/BundleSlider";
import Popup from "../components/popup";
import {AiOutlineCloseCircle} from "react-icons/all";
import keycloak from "../keycloak";
import 'animate.css';
import {Helmet} from "react-helmet";

import CourseBundleImg from '../imgs/course-bundle.jpg'


class Home extends Component {
    state = {
        alldata: [],
        volfeatures: [],
        isLoading: true,
        metatittle: '',
        hide500: false,
        metaItemsName: [],
        metaItemsProperty: []
    };

    hide500Form = () => {
        this.setState({hide500: true})
    }

    async componentDidMount() {



        let currentOffset = window.pageYOffset;
        this.setState({currentOffset})

        let lang = "";
        if (
            localStorage.getItem("i18nextLng") === "en" ||
            localStorage.getItem("i18nextLng") === "en-US"
        ) {
            this.setState({lang: "en"});
            lang = "en";
        } else {
            this.setState({lang: "ar"});
            lang = "ar";
        }



        await axios.get(process.env.REACT_APP_API_URL, {
            params: {
                url: "api/resource/VOL%20Home%20Page/Home-" + lang
            }
        })
            .then((response) => {
                let alldata = response.data.data;
                let volfeatures = response.data.data.vol_features;
                this.setState({alldata});
                this.setState({volfeatures});
                this.setState({
                    isLoading: false,
                });
            });

        axios.get(process.env.REACT_APP_API_URL, {
            params: {
                url: "api/resource/VTSMetaTags/Home_VOL_" + lang
            }
        }).then((response) => {
            let metaItemsName = response.data.data.meta_tags.filter((res) => {
                if (res.type === "name") {
                    return res
                }
            })
            let metaItemsProperty = response.data.data.meta_tags.filter((res) => {
                if (res.type === "property") {
                    return res
                }
            })
            this.setState({metaItemsName, metaItemsProperty})
            let metatittle = response.data.data.page_title;
            this.setState({metatittle: metatittle})

        });

        // Close opening nav menu.
        if (window.innerWidth <= 768) {
            window.addEventListener('scroll', () => {
                let currentOffset = this.state.currentOffset;

                if (window.pageYOffset !== currentOffset) {
                    document.getElementById("navbarSupportedContent").classList.remove('show');
                    let newOffset = window.pageYOffset;
                    this.setState({currentOffset: newOffset})
                }
            })
        }
    }

    render() {
        const {t} = this.props;
        let {alldata, volfeatures} = this.state;

        let singleMetaName = this.state.metaItemsName.map(res => {
            return <meta key={res.key} name={res.key} content={res.value}/>
        })
        let singleMetaProperty = this.state.metaItemsProperty.map(res => {
            return <meta key={res.key} property={res.key} content={res.value}/>
        })

        const features = volfeatures.map((res) => {
            return (

                <div
                    className=" cardfeat col-lg-4 col-md-6 my-5 text-center"
                    key={res.name}
                >
                    <img
                        className="imgsec3"
                        alt={res.icon_alt}
                        src={res.icon}
                    />
                    <h3 className="head5 mt-3 text-center"> {res.title}</h3>
                    <h3 className="head8 mt-3 text-center"> {res.description} </h3>
                </div>

            )
        })

        let rendered = this.state.isLoading ? (
            <div className="container cont">
                <div className="row">
                    <div className="col-lg-12 col-md-12">
                        <div className="example">
                            <Spin size="large" style={{margin: "auto"}}/>
                        </div>
                    </div>
                </div>
            </div>
        ) : (
            <>
                <div id="Home " className=" web  mb-5 ">
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-lg-5 col-md-12 m-auto ">
                                <div className="firstsec">
                                    <h1 className="pb-2 bighead">{alldata.banner_title}</h1>
                                    <h3 className="suptxt pb-2">{alldata.banner_sub_title} </h3>
                                    <p className="pb-2 pharagraph">{alldata.banner_description}</p>
                                    <h3 className="suptxt pb-3"> {t("Powerd By Variiance")} </h3>
                                    <NavHashLink
                                        to="/#Top"
                                    >
                                        <div className="bg-btn2">
                                            <button
                                                className="Label-btn1 size"
                                            >
                                                {t("Top Picks Courses")}
                                            </button>
                                        </div>
                                    </NavHashLink>
                                </div>
                            </div>
                            <div className="col-lg-7 col-md-12 p-0 mt-5 bannerimg">
                                <img width="100%"
                                     alt="home page courses"
                                     src={localStorage.getItem("i18nextLng") === "en" ||
                                     localStorage.getItem("i18nextLng") === "en-US" ? homeImg : homeImgAR}/>
                            </div>
                        </div>
                    </div>
                </div>


                <div id="Home" className="  mob mt-5 mb-5 ">
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-lg-7 col-md-12 mt-5 p-0">
                                <img width="100%"
                                     alt="home page courses"
                                     src={localStorage.getItem("i18nextLng") === "en" ||
                                     localStorage.getItem("i18nextLng") === "en-US" ? homeImg : homeImgAR}/>
                            </div>
                            <div className="col-lg-5 col-md-12 m-auto pt-3 ">
                                <div className="firstsec">
                                    <h1>{alldata.banner_title}</h1>
                                    <h3 className="suptxt">{alldata.banner_sub_title} </h3>
                                    <p>{alldata.banner_description}</p>
                                    <h3 className="suptxt pb-3"> {t("Powerd By Variiance")} </h3>
                                    <NavHashLink
                                        to="/#Top"
                                    >
                                        <div className="bg-btn2">
                                            <button
                                                className="Label-btn1 size"
                                            >
                                                {t("Top Picks Courses")}
                                            </button>
                                        </div>
                                    </NavHashLink>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div id="about" className="container-fluid homesec">
                    <div className="row">
                        <div className="col-md-6 p-0"><img width="100%" src={alldata.about_image}
                                                           alt={alldata.about_image_alt}/></div>
                        <div className="col-md-6 m-auto py-5">
                            <div className="divabout">
                                <h2>{alldata.about_title}</h2>
                                <hr className="hr1"/>
                                <p>
                                    {alldata.about_description}
                                </p>
                            </div>
                        </div>
                    </div>
                </div>


                <div id="Features" className="container pt-5 homesec">
                    <h2 className="my-5"> {t("VOL Features")} </h2>
                    <div className="row">


                        {features}


                    </div>
                </div>


                <div id="Top" className="container py-5">
                    <h2 className="my-5"> {t("Top Picks")} </h2>

                    <Slider/>

                </div>

                <div id="course-bundle" className="container py-5">
                    <h2> {t("top-courses-bundle")}</h2>
                    <BundleSlider/>
                </div>

                <div id="vtalks" className="container my-5 homesec">
                    <div className="row">
                        <div className="col-md-6 m-auto py-5">
                            <div className="divabout">
                                <h2> {t("V-Talks")}</h2>
                                <hr className="hr1"/>
                                <p>
                                    {alldata.vtalks_description}
                                </p>
                            </div>
                        </div>
                        <div className="col-md-6"><img width="100%" src={alldata.vtalks_image}
                                                       alt={alldata.vtalks_image_alt}/></div>
                    </div>
                </div>


                <div className="container my-5 homesec">
                    <div className="row">
                        <div className="col-md-6">
                            <img width="100%" src={alldata.upcoming_image} alt={alldata.upcoming_image_alt}/>
                        </div>
                        <div className="col-md-6 m-auto py-5">
                            <div className="divabout">
                                <h2> {t("Upcoming Courses")} </h2>
                                <hr className="hr1"/>
                                <p>
                                    {alldata.upcoming_description}
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
                {/*<Popup/>*/}
                {/*{keycloak && !keycloak.authenticated ?*/}
                {/*    <div*/}
                {/*        className="animate__animated animate__lightSpeedInLeft modalsec2 modal-dialog modal-dialog-2 modal-dialog-centered "*/}
                {/*        style={{display: this.state.hide500 ? 'none' : null}}*/}
                {/*    >*/}
                {/*        <div className="modal-content modal-content-2">*/}
                {/*            <button type="button" className="text-end btn"*/}
                {/*            ><AiOutlineCloseCircle onClick={() => this.hide500Form()} color="#fff" size="35"/>*/}
                {/*            </button>*/}
                {/*            <div className="modal-body">*/}
                {/*                <div className="text-center">*/}
                {/*                    <img*/}
                {/*                        alt="message us"*/}
                {/*                        src="http://vcloud.variiance.com/link/g5CnqcUZyM6uICG5XFq1fLvlqi8Piwo0LhQU9yhb.png"*/}
                {/*                        className="text-center popupImg"/>*/}
                {/*                    <div className="my-5">*/}
                {/*                        <p className="popupTxt">{t('popuptxt')}</p>*/}
                {/*                        <p className="popupTxt">{t('or')}</p>*/}
                {/*                        <p className="popupTxt mb-3">{t('Video conference packages on VLC')}</p>*/}
                {/*                        <a href={process.env.REACT_APP_500} target="_blank">*/}
                {/*                            <button*/}
                {/*                                className="popupButton"><span*/}
                {/*                                className="popupButtonTxt">{t('popupButton')}</span></button>*/}
                {/*                        </a>*/}
                {/*                    </div>*/}
                {/*                </div>*/}
                {/*            </div>*/}
                {/*        </div>*/}
                {/*    </div> : null}*/}
            </>
        );
        return <div>
            {rendered}
            <Helmet>
                <link href="https://vol.variiance.com/" rel="canonical"/>
            </Helmet>
            <MetaTags>
                <title>{this.state.metatittle}</title>
                {singleMetaName}
                {singleMetaProperty}
            </MetaTags>
        </div>;
    }
}

export default withTranslation()(Home);
